import { ChainId } from '@udegoswap/sdk';
import { Token } from "@udegoswap/sdk";
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import {getAddress} from "@ethersproject/address";
import { DEFAULT_LIST_OF_LISTS , UNSUPPORTED_LIST_URLS } from '../../config/constants/lists';
import DEFAULT_TOKEN_LIST from '../../config/constants/tokenLists/pancake-default.tokenlist.json';

import UNSUPPORTED_TOKEN_LIST from '../../config/constants/tokenLists/pancake-unsupported.tokenlist.json';
// use ordering of default list of lists to assign priority
function sortByListPriority(urlA, urlB) {
    const first = DEFAULT_LIST_OF_LISTS.includes(urlA) ? DEFAULT_LIST_OF_LISTS.indexOf(urlA) : Number.MAX_SAFE_INTEGER;
    const second = DEFAULT_LIST_OF_LISTS.includes(urlB) ? DEFAULT_LIST_OF_LISTS.indexOf(urlB) : Number.MAX_SAFE_INTEGER;
    // need reverse order to make sure mapping includes top priority last
    if (first < second)
        return 1;
    if (first > second)
        return -1;
    return 0;
}
/**
 * Token instances created from token info.
 */
export class WrappedTokenInfo extends Token {
    constructor(tokenInfo, tags) {
        super(tokenInfo.chainId, tokenInfo.address, tokenInfo.decimals, tokenInfo.symbol, tokenInfo.name);
        this.tokenInfo = tokenInfo;
        this.tags = tags;
    }

    get logoURI() {
        return this.tokenInfo.logoURI;
    }
}
/**
 * An empty result, useful as a default.
 */
const EMPTY_LIST = {
    [ChainId.MAINNET]: {},
    [ChainId.TESTNET]: {},
};
const listCache = typeof WeakMap !== 'undefined' ? new WeakMap() : null;
export function listToTokenMap(list) {
    // eslint-disable-next-line no-cond-assign
    const result = listCache === null || listCache === undefined ? undefined : listCache.get(list);
    if (result)
        return result;
    const map = list.tokens.reduce((tokenMap, tokenInfo) => {
        let _a; let _b; let _c;
        // eslint-disable-next-line no-cond-assign
        const tags = (_c = (_b = (_a = tokenInfo.tags) === null || _a === undefined ? undefined : _a.map((tagId) => {
            let _a;
            // eslint-disable-next-line no-cond-assign
            if (!((_a = list.tags) === null || _a === undefined ? undefined : _a[tagId]))
                return undefined;
            return {...list.tags[tagId], id: tagId};
            // eslint-disable-next-line no-cond-assign
        })) === null || _b === undefined ? undefined : _b.filter((x) => Boolean(x))) !== null && _c !== undefined ? _c : [];
        const token = new WrappedTokenInfo(tokenInfo, tags);
        if (tokenMap[token.chainId][token.address] !== undefined)
            throw Error('Duplicate tokens.');
        return {...tokenMap, [token.chainId]: {...tokenMap[token.chainId], [token.address]: {
                    token,
                    list,
                }}};
    }, { ...EMPTY_LIST});
    // eslint-disable-next-line no-cond-assign,no-unused-expressions
    listCache === null || listCache === undefined ? undefined : listCache.set(list, map);
    return map;
}
export function useAllLists() {
    return useSelector((state) => state.lists.byUrl);
}
function combineMaps(map1, map2) {
    return {
        [ChainId.MAINNET]: {...map1[ChainId.MAINNET], ...map2[ChainId.MAINNET]},
        [ChainId.TESTNET]: {...map1[ChainId.TESTNET], ...map2[ChainId.TESTNET]},
    };
}
// merge tokens contained within lists from urls
function useCombinedTokenMapFromUrls(urls) {
    const lists = useAllLists();
    return useMemo(() => {
        if (!urls)
            return EMPTY_LIST;
        return (urls
            .slice()
            // sort by priority so top priority goes last
            .sort(sortByListPriority)
            .reduce((allTokens, currentUrl) => {
            let _a;
            // eslint-disable-next-line no-cond-assign
            const current = (_a = lists[currentUrl]) === null || _a === undefined ? undefined : _a.current;
            if (!current)
                return allTokens;
            try {
                const newTokens = Object.assign(listToTokenMap(current));
                return combineMaps(allTokens, newTokens);
            }
            catch (error) {
                console.error('Could not show token list due to error', error);
                return allTokens;
            }
        }, EMPTY_LIST));
    }, [lists, urls]);
}
// filter out unsupported lists
export function useActiveListUrls() {
    return useSelector((state) => state.lists.activeListUrls)?.filter(
        (url) => !UNSUPPORTED_LIST_URLS.includes(url),
    )
}
export function useInactiveListUrls() {
    const lists = useAllLists();
    const allActiveListUrls = useActiveListUrls();
    // eslint-disable-next-line no-cond-assign
    return Object.keys(lists).filter((url) => !(allActiveListUrls === null || allActiveListUrls === undefined ? undefined : allActiveListUrls.includes(url)) && !UNSUPPORTED_LIST_URLS.includes(url));
}
// get all the tokens from active lists, combine with local default tokens
export function useCombinedActiveList() {
    const activeListUrls = useActiveListUrls();
    const activeTokens = useCombinedTokenMapFromUrls(activeListUrls);
    const defaultTokenMap = listToTokenMap(DEFAULT_TOKEN_LIST);
    return combineMaps(activeTokens, defaultTokenMap);
}
// all tokens from inactive lists
export function useCombinedInactiveList() {
    const allInactiveListUrls = useInactiveListUrls();
    return useCombinedTokenMapFromUrls(allInactiveListUrls);
}
// used to hide warnings on import for default tokens
export function useDefaultTokenList() {
    return listToTokenMap(DEFAULT_TOKEN_LIST);
}
// list of tokens not supported on interface, used to show warnings and prevent swaps and adds
export function useUnsupportedTokenList() {
    // get hard coded unsupported tokens
    const localUnsupportedListMap = listToTokenMap(UNSUPPORTED_TOKEN_LIST);
    // get any loaded unsupported tokens
    const loadedUnsupportedListMap = useCombinedTokenMapFromUrls(UNSUPPORTED_LIST_URLS);
    // format into one token address map
    return combineMaps(localUnsupportedListMap, loadedUnsupportedListMap);
}
export function useIsListActive(url) {
    const activeListUrls = useActiveListUrls();
    // eslint-disable-next-line
    return Boolean(activeListUrls === null || activeListUrls === undefined ? undefined : activeListUrls.includes(url));
}