import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import Sidebar from './Sidebar';
import Dashboard from './Dashboard';
import reportWebVitals from './reportWebVitals';

import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Provider as ReduxProvider } from 'react-redux';
import { Web3ReactProvider } from "@web3-react/core";
import {ToastContainer} from "react-toastify";
import { store, persistor } from './redux/store';
import {getLibrary} from "./utils/web3React";
import { PersistGate } from 'redux-persist/lib/integration/react';

import ListsUpdater from './state/lists/updater'
import MulticallUpdater from './state/multicall/updater'
import TransactionUpdater from './state/transactions/updater'
import { ToastsProvider } from './contexts/ToastsContext'
import 'react-toastify/dist/ReactToastify.css';

function Updaters() {
  return (
    <>
      <ListsUpdater />
      <TransactionUpdater />
      <MulticallUpdater />
    </>
  )
}

ReactDOM.render(
  <Web3ReactProvider getLibrary={getLibrary}>
    <ReduxProvider store={store}>
      <ToastsProvider>
        <HelmetProvider>
          <PersistGate loading={<div>Loading...</div>} persistor={persistor}>
            <BrowserRouter>
              <Updaters />
              <App />
              <ToastContainer />
            </BrowserRouter>
          </PersistGate>
        </HelmetProvider>
      </ToastsProvider>
    </ReduxProvider>
  </Web3ReactProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(// console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
