import BigNumber from 'bignumber.js';
import erc20ABI from '../../config/abi/erc20.json';
import masterchefABI from '../../config/abi/masterchef.json';
import multicall from '../../utils/multicall';
import { getAddress, getMasterChefAddress } from '../../utils/addressHelpers';

export const fetchFarmUserAllowances = async (account, farmsToFetch) => {
    const masterChefAddress = getMasterChefAddress();
    const calls = farmsToFetch.map((farm) => {
        const lpContractAddress = getAddress(farm.lpAddresses);
        return { address: lpContractAddress, name: 'allowance', params: [account, masterChefAddress] };
    });
    const rawLpAllowances = await multicall(erc20ABI, calls);
    const parsedLpAllowances = rawLpAllowances.map((lpBalance) => new BigNumber(lpBalance).toJSON());
    return parsedLpAllowances;
};
export const fetchFarmUserTokenBalances = async (account, farmsToFetch) => {
    const calls = farmsToFetch.map((farm) => {
        const lpContractAddress = getAddress(farm.lpAddresses);
        return {
            address: lpContractAddress,
            name: 'balanceOf',
            params: [account],
        };
    });
    const rawTokenBalances = await multicall(erc20ABI, calls);
    const parsedTokenBalances = rawTokenBalances.map((tokenBalance) => new BigNumber(tokenBalance).toJSON());
    return parsedTokenBalances;
};
export const fetchFarmUserStakedBalances = async (account, farmsToFetch) => {
    const masterChefAddress = getMasterChefAddress();
    const calls = farmsToFetch.map((farm) => ({
            address: masterChefAddress,
            name: 'userInfo',
            params: [farm.pid, account],
        }));
    const rawStakedBalances = await multicall(masterchefABI, calls);
    const parsedStakedBalances = rawStakedBalances.map((stakedBalance) => new BigNumber(stakedBalance[0]._hex).toJSON());
    return parsedStakedBalances;
};
export const fetchFarmUserEarnings = async (account, farmsToFetch) => {
    const masterChefAddress = getMasterChefAddress();
    const calls = farmsToFetch.map((farm) => ({
            address: masterChefAddress,
            name: 'pendingMLX',
            params: [farm.pid, account],
        }));
    const rawEarnings = await multicall(masterchefABI, calls);
    const parsedEarnings = rawEarnings.map((earnings) => new BigNumber(earnings).toJSON());
    return parsedEarnings;
};

export const fetchFarmUserInvested = async (account, farmsToFetch) => {
    const masterChefAddress = getMasterChefAddress();
    const calls = farmsToFetch.map((farm) => ({
        address: masterChefAddress,
        name: 'userInfo',
        params: [farm.pid, account],
    }));
    const rawStakedBalances = await multicall(masterchefABI, calls);
    const parsedStakedBalances = rawStakedBalances.map((stakedBalance) => parseFloat(stakedBalance._lastInvested));
    return parsedStakedBalances;
};