import logo from './logo.svg';
import './App.css';
import TradingViewWidget, { Themes } from 'react-tradingview-widget';
import React, { Component }  from 'react';


function Dashboard() {
  return (
    <div className='mx-auto lg:ml-64'>
      <div className='hero-section px-5 py-4 md:px-10 md:py-8 h-full relative'>
        <nav className="flex justify-between mb-4">
          <h2 className="title text-black dark:text-white text-opacity-75 text-3xl font-bold">Exchange</h2>
          {/* Dark mode switcher */}
          <button id="theme-toggle" type="button" className="text-indigo-600 dark:text-gray-100 hover:bg-indigo-200 dark:hover:bg-white dark:hover:bg-opacity-25 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 rounded-lg text-sm p-2 animate-bounce">
            <svg id="theme-toggle-dark-icon" className="w-5 h-5 hidden" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path d="M17.293 13.293A8 8 0 016.707 2.707a8.001 8.001 0 1010.586 10.586z" />
            </svg>
            <svg id="theme-toggle-light-icon" className="w-5 h-5 hidden" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path d="M10 2a1 1 0 011 1v1a1 1 0 11-2 0V3a1 1 0 011-1zm4 8a4 4 0 11-8 0 4 4 0 018 0zm-.464 4.95l.707.707a1 1 0 001.414-1.414l-.707-.707a1 1 0 00-1.414 1.414zm2.12-10.607a1 1 0 010 1.414l-.706.707a1 1 0 11-1.414-1.414l.707-.707a1 1 0 011.414 0zM17 11a1 1 0 100-2h-1a1 1 0 100 2h1zm-7 4a1 1 0 011 1v1a1 1 0 11-2 0v-1a1 1 0 011-1zM5.05 6.464A1 1 0 106.465 5.05l-.708-.707a1 1 0 00-1.414 1.414l.707.707zm1.414 8.486l-.707.707a1 1 0 01-1.414-1.414l.707-.707a1 1 0 011.414 1.414zM4 11a1 1 0 100-2H3a1 1 0 000 2h1z" fillRule="evenodd" clipRule="evenodd" />
            </svg>
          </button>
          {/* Dark mode switcher end */}
        </nav>
        <div className="w-full">
          <section className>
            <div className="mx-auto">
              <div className="grid grid-cols-1 md:grid-cols-3 gap-5 md:gap-8 mb-8">
                <div className="col-span-1 md:col-span-1">
                  <div className="px-6 py-6 bg-white dark:bg-opacity-30 rounded-3xl shadow-2xl dark:shadow-xl hover:shadow-3xl transition-all">
                    <div className="flex flex-wrap">
                      <div className="w-full md:w-1/2 md:pt-6 mb-3 md:mb-0">
                        <h3 className="mb-2 text-2xl font-bold text-white text-center md:text-left">
                          <span className="dark:text-white text-indigo-700">$411322.319</span>
                        </h3>
                        <p className="md:mb-8 text-sm font-medium text-black dark:text-indigo-100 text-center md:text-left">Total Liquidity Locked</p>
                      </div>
                      <div className="w-full md:w-1/2 flex items-center">
                        <img className="animate-pulse mx-auto h-24" src="images/user-image.svg" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-span-1 md:col-span-1">
                  <div className="px-6 py-6 bg-white dark:bg-opacity-25 rounded-3xl shadow-2xl dark:shadow-xl hover:shadow-3xl transition-all">
                    <div className="flex flex-wrap">
                      <div className="w-full md:w-1/2 md:pt-6 mb-3 md:mb-0">
                        <h3 className="mb-2 text-2xl font-bold text-white text-center md:text-left">
                          <span className="dark:text-white text-orange-700">0.160 MTPL</span>
                        </h3>
                        <p className="md:mb-8 text-sm font-medium text-black dark:text-indigo-100 text-center md:text-left">Emission Per Block</p>
                      </div>
                      <div className="w-full md:w-1/2 flex items-center">
                        <img className="animate-pulse mx-auto h-24" src="images/clouds.png" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-span-1 md:col-span-1">
                  <div className="px-6 py-6 bg-white dark:bg-opacity-25 rounded-3xl shadow-2xl dark:shadow-xl hover:shadow-3xl transition-all">
                    <div className="flex flex-wrap">
                      <div className="w-full md:w-1/2 md:pt-6 mb-3 md:mb-0">
                        <h3 className="mb-2 text-2xl font-bold text-white text-center md:text-left">
                          <span className="dark:text-white text-green-700">$00.00</span>
                        </h3>
                        <p className="md:mb-8 text-sm font-medium text-black dark:text-indigo-100 text-center md:text-left">Your Balance - 0 MTPL</p>
                      </div>
                      <div className="w-full md:w-1/2 flex items-center">
                        <img className="animate-pulse mx-auto h-24" src="images/wallet.png" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div className="w-full grid grid-cols-1 md:grid-cols-10 gap-8 justify-evenly align-center-middle">
            <div className="chart-box col-span-1 md:col-span-6">
              <div className="border-gray-200 w-100 h-full">
                {/*<div class="pt-6 text-center bg-white rounded">
                                          <div class="chart" data-type="area-small" data-variant="indigo"></div>
                                      </div>*/}
                <div className="overflow-hidden rounded-3xl">
                  {/* <div class="py-3 px-5 bg-gray-50">Line chart</div> */}
                  {/* <canvas class="md:p-2" id="chartLine"></canvas> */}
                  {/* <div id="chart_container" style={{height: '430px', minWidth: '310px'}} /> */}
                  <TradingViewWidget
                    symbol="NASDAQ:AAPL"
                    theme={Themes.DARK}
                    locale="fr"
                    // autosize
                  />
                </div>
              </div>
            </div>
            <div className="relative col-span-1 md:col-span-4 justify-center">
              <div className="p-6 border-gray-100 dark:border-gray-300 shadow-xl hover:shadow-2xl bg-white dark:bg-black bg-opacity-50 dark:bg-opacity-25 rounded-3xl transition-all">
                <div className="flex flex-row items-center justify-between mb-3 border-b border-gray-100 pb-5">
                  <div className="flex flex-row items-center">
                    <div className="chart-icon text-gray-500 hover:text-white dark:text-gray-400 hover:bg-block dark:hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 text-sm px-3 py-2 border border-gray-300 cursor-pointer mr-2 hover:border-blue-800 hover:bg-blue-800 transition-all"><i className="las la-chart-line text-xl" /></div>
                    <h5 className="text-xl font-bold text-black dark:text-white items-start">Swap</h5>
                  </div>
                  <button type="button" className="text-gray-500 dark:text-gray-400 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 h-5 w-5">
                    <i className="las la-redo-alt las la-redo-alt text-2xl text-gray-500 hover:animate-spin" />
                  </button>
                </div>
                <div className="py-3">
                  <div className="token-select-input">
                    <div className="text-2sm text-black dark:text-white text-opacity-50 hover:text-opacity-100 font-semibold">From</div>
                    <div className="token-row flex flex-row items-center justify-between my-2">
                      <a href="javascript:;" className="select-source-token token-row flex flex-row items-center justify-start">
                        <div className="tokan-icon mr-2 w-6"><img src="images/bcoin.svg" alt="" /></div>
                        <div className="token-name text-xl text-black dark:text-white font-semibold mt-1">BTC</div>
                        <i className="las la-angle-down ml-2 text-black dark:text-white" />
                      </a>
                      <div className="tokan-input">
                        <input type="text" className="bg-transparent border-0 p-0 bg-gray-100 w-full outline-none text-2xl appearance-none" inputMode="decimal" maxLength={18} x-inputmode="decimal" tabIndex={0} defaultValue={1} oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" />
                      </div>
                    </div>
                    <div className="flex flex-row items-center justify-between">
                      <div className="text-sm text-black dark:text-gray-400 hover:text-indigo-500 hover:text-white">BNB</div>
                      <div className="text-sm text-black dark:text-gray-400 hover:text-indigo-500 hover:text-white font-semibold">~$536.71</div>
                    </div>
                  </div>
                </div>
                <div className="separator flex align-middle justify-center">
                  <a href="javascript:;" className="shadow-2xl p-1"><i className="las la-arrow-down text-blue-900" /></a>
                </div>
                <div className="destination-token p-3 border-2 border-gray-300 dark:border-white border-opacity-25">
                  <div className="destination-token">
                    <div className="flex flex-row items-center justify-between">
                      <a href="javascript:;" target="_blank" className="text-sm text-gray-400 hover:text-indigo-500 dark:hover:text-white">To (estimated)</a>
                      <div className="text-sm text-black dark:text-gray-400 hover:text-indigo-500 dark:hover:text-white">Balance: 0.00</div>
                    </div>
                    <div className="token-row flex flex-row items-center justify-between my-2">
                      <a href="javascript:;" className="select-source-token token-row flex flex-row items-center justify-start">
                        <div className="tokan-icon mr-2 w-6"><img src="images/doge.svg" alt="" /></div>
                        <div className="token-name text-xl text-black dark:text-white font-semibold mt-1">DOGE</div>
                        <i className="las la-angle-down ml-2 text-black dark:text-white" />
                      </a>
                    </div>
                  </div>
                  {/*<div class="quotes-list mt-2">
                                              <button class="quote-button p-3 border border-2 w-full">
                                                  <div class="quote-data-row flex flex-row items-center justify-between text-xl">
                                                      <div class="market-name">1inch</div>
                                                      <div class="market-token-amount">0.16901</div>
                                                  </div>
                                                  <div class="quote-data-row flex flex-row items-center justify-between text-sm text-gray-700">
                                                      <div class="quote-preview-tx-cost">
                                                          <span>Tx cost</span>
                                                          <span> 0.0049 Ξ </span>
                                                          <span>~$20.1</span>
                                                      </div>
                                                      <div class="quote-price">
                                                          <span> ~$0.17 </span>
                                                          <span>(-5.18%)</span>
                                                      </div>
                                                  </div>
                                              </button>
                                          </div>*/}
                </div>
                <button className="px-2 py-4 mt-4 text-center text-white bg-gradient-to-r from-indigo-900 to-blue-900 rounded-lg w-full font-medium hover:from-blue-900 hover:to-indigo-900 transition-all" disabled><span>Connect Wallet</span></button>
              </div>
              <div className="select-token fixed top-0 left-0 w-full h-full z-40 py-10 md:py-20 flex align-center justify-center bg-black bg-opacity-60" style={{display: 'none'}}>
                <div className="border-gray-200 shadow-2xl bg-white dark:bg-black bg-opacity-100 dark:bg-opacity-50 rounded-3xl backdrop-blur-md w-50 h-auto md:my-10">
                  <div className="flex flex-row items-center justify-between mb-4 border-b border-gray-200 dark:border-gray-100 py-5 px-5">
                    <div className="flex flex-row items-center">
                      <div className="back-to-token text-gray-500 hover:text-white dark:text-gray-400 hover:bg-block dark:hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 text-sm px-3 py-2 border border-gray-300 cursor-pointer mr-2 hover:border-blue-800 hover:bg-blue-800 transition-all"><i className="las la-angle-left" /></div>
                      <h5 className="text-xl font-bold text-black dark:text-white items-start">Select a Token</h5>
                    </div>
                  </div>
                  <form action role="search" noValidate className="DocSearch-Form px-5 py-4 mx-4 border border-gray-200 dark:border-gray-100 hover:border-indigo-600 dark:border-transparent dark:hover:border-white mb-2 rounded-xl">
                    <label className="DocSearch-MagnifierLabel" htmlFor="docsearch-input" id="docsearch-label">
                      <svg width={20} height={20} className="DocSearch-Search-Icon" viewBox="0 0 20 20"><path d="M14.386 14.386l4.0877 4.0877-4.0877-4.0877c-2.9418 2.9419-7.7115 2.9419-10.6533 0-2.9419-2.9418-2.9419-7.7115 0-10.6533 2.9418-2.9419 7.7115-2.9419 10.6533 0 2.9419 2.9418 2.9419 7.7115 0 10.6533z" stroke="stroke-black dark:stroke-white" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round" /></svg>
                    </label>
                    <input className="DocSearch-Input font-semibold" aria-autocomplete="list" aria-labelledby="docsearch-label" id="docsearch-input" autoComplete="off" autoCorrect="off" autoCapitalize="off" spellCheck="false" placeholder="Search Tokens" maxLength={64} type="search" defaultValue />
                    <button type="reset" title="Clear the query" className="DocSearch-Reset" hidden><svg width={20} height={20} viewBox="0 0 20 20"><path d="M10 10l5.09-5.09L10 10l5.09 5.09L10 10zm0 0L4.91 4.91 10 10l-5.09 5.09L10 10z" stroke="#ffffff" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round" /></svg></button>
                  </form>
                  <div className="favourite-tokens-container flex flex-wrap align-middle justify-center p-3 px-2 border-b-2 border-gray-100 border-opacity-25">
                    <a href="javascrip:;" className="favourite-token px-2 py-2 flex align-middle justify-center bg-white bg-opacity-25 border border-gray-500 dark:border-gray-100 m-1 relative rounded-md hover:border-gray-700 dark:hover:border-white"><div className="rounded-full overflow-hidden w-6 mx-1"><img src="images/dollar.svg" className="w-full rounded-3xl" alt="" /></div><span className="text-sm text-black dark:text-white mr-2 mt-1">BNB</span><span className="favorite-close text-gray-600 hover:text-red-800"><i className="las la-times" /></span></a>
                    <a href="javascrip:;" className="favourite-token px-2 py-2 flex align-middle justify-center bg-white bg-opacity-25 border border-gray-500 dark:border-gray-100 m-1 relative rounded-md hover:border-gray-700 dark:hover:border-white"><div className="rounded-full overflow-hidden w-6 mx-1"><img src="images/dollar.svg" className="w-full rounded-3xl" alt="" /></div><span className="text-sm text-black dark:text-white mr-2 mt-1">BNB</span><span className="favorite-close text-gray-600 hover:text-red-800"><i className="las la-times" /></span></a>
                    <a href="javascrip:;" className="favourite-token px-2 py-2 flex align-middle justify-center bg-white bg-opacity-25 border border-gray-500 dark:border-gray-100 m-1 relative rounded-md hover:border-gray-700 dark:hover:border-white"><div className="rounded-full overflow-hidden w-6 mx-1"><img src="images/dollar.svg" className="w-full rounded-3xl" alt="" /></div><span className="text-sm text-black dark:text-white mr-2 mt-1">BNB</span><span className="favorite-close text-gray-600 hover:text-red-800"><i className="las la-times" /></span></a>
                    <a href="javascrip:;" className="favourite-token px-2 py-2 flex align-middle justify-center bg-white bg-opacity-25 border border-gray-500 dark:border-gray-100 m-1 relative rounded-md hover:border-gray-700 dark:hover:border-white"><div className="rounded-full overflow-hidden w-6 mx-1"><img src="images/dollar.svg" className="w-full rounded-3xl" alt="" /></div><span className="text-sm text-black dark:text-white mr-2 mt-1">BNB</span><span className="favorite-close text-gray-600 hover:text-red-800"><i className="las la-times" /></span></a>
                  </div>
                  <div className="rounded-b-3xl overflow-hidden">
                    <div className="token-list">
                      <a href="#" className="tokens-list-item p-3 flex flex-row items-center justify-between hover:bg-black hover:bg-opacity-5 dark:hover:bg-white dark:hover:bg-opacity-25">
                        <div className="token-info flex flex-row items-center justify-start">
                          <div className="tokan-icon mr-2"><img src="images/bcoin.svg" alt="" /></div>
                          <div className="tokan-info">
                            <div className="text-black dark:text-white text-sm font-semibold">USD Coin</div>
                            <div className="text-black dark:text-white text-opacity-50 text-xs">USDC</div>
                          </div>
                        </div>
                        <div className="flex flex-row items-center justify-end">
                          <div className="text-black dark:text-white text-opacity-50 text-2sm mr-2">0</div>
                        </div>
                      </a>
                      <a href="#" className="tokens-list-item p-3 flex flex-row items-center justify-between hover:bg-black hover:bg-opacity-5 dark:hover:bg-white dark:hover:bg-opacity-25">
                        <div className="token-info flex flex-row items-center justify-start">
                          <div className="tokan-icon mr-2"><img src="images/bcoin.svg" alt="" /></div>
                          <div className="tokan-info">
                            <div className="text-black dark:text-white text-sm font-semibold">USD Coin</div>
                            <div className="text-black dark:text-white text-opacity-50 text-xs">USDC</div>
                          </div>
                        </div>
                        <div className="flex flex-row items-center justify-end">
                          <div className="text-black dark:text-white text-opacity-50 text-2sm mr-2">0</div>
                        </div>
                      </a>
                      <a href="#" className="tokens-list-item p-3 flex flex-row items-center justify-between hover:bg-black hover:bg-opacity-5 dark:hover:bg-white dark:hover:bg-opacity-25">
                        <div className="token-info flex flex-row items-center justify-start">
                          <div className="tokan-icon mr-2"><img src="images/bcoin.svg" alt="" /></div>
                          <div className="tokan-info">
                            <div className="text-black dark:text-white text-sm font-semibold">USD Coin</div>
                            <div className="text-black dark:text-white text-opacity-50 text-xs">USDC</div>
                          </div>
                        </div>
                        <div className="flex flex-row items-center justify-end">
                          <div className="text-black dark:text-white text-opacity-50 text-2sm mr-2">0</div>
                        </div>
                      </a>
                      <a href="#" className="tokens-list-item p-3 flex flex-row items-center justify-between hover:bg-black hover:bg-opacity-5 dark:hover:bg-white dark:hover:bg-opacity-25">
                        <div className="token-info flex flex-row items-center justify-start">
                          <div className="tokan-icon mr-2"><img src="images/bcoin.svg" alt="" /></div>
                          <div className="tokan-info">
                            <div className="text-black dark:text-white text-sm font-semibold">USD Coin</div>
                            <div className="text-black dark:text-white text-opacity-50 text-xs">USDC</div>
                          </div>
                        </div>
                        <div className="flex flex-row items-center justify-end">
                          <div className="text-black dark:text-white text-opacity-50 text-2sm mr-2">0</div>
                        </div>
                      </a>
                      <a href="#" className="tokens-list-item p-3 flex flex-row items-center justify-between hover:bg-black hover:bg-opacity-5 dark:hover:bg-white dark:hover:bg-opacity-25">
                        <div className="token-info flex flex-row items-center justify-start">
                          <div className="tokan-icon mr-2"><img src="images/bcoin.svg" alt="" /></div>
                          <div className="tokan-info">
                            <div className="text-black dark:text-white text-sm font-semibold">USD Coin</div>
                            <div className="text-black dark:text-white text-opacity-50 text-xs">USDC</div>
                          </div>
                        </div>
                        <div className="flex flex-row items-center justify-end">
                          <div className="text-black dark:text-white text-opacity-50 text-2sm mr-2">0</div>
                        </div>
                      </a>
                      <a href="#" className="tokens-list-item p-3 flex flex-row items-center justify-between hover:bg-black hover:bg-opacity-5 dark:hover:bg-white dark:hover:bg-opacity-25">
                        <div className="token-info flex flex-row items-center justify-start">
                          <div className="tokan-icon mr-2"><img src="images/bcoin.svg" alt="" /></div>
                          <div className="tokan-info">
                            <div className="text-black dark:text-white text-sm font-semibold">USD Coin</div>
                            <div className="text-black dark:text-white text-opacity-50 text-xs">USDC</div>
                          </div>
                        </div>
                        <div className="flex flex-row items-center justify-end">
                          <div className="text-black dark:text-white text-opacity-50 text-2sm mr-2">0</div>
                        </div>
                      </a>
                      <a href="#" className="tokens-list-item p-3 flex flex-row items-center justify-between hover:bg-black hover:bg-opacity-5 dark:hover:bg-white dark:hover:bg-opacity-25">
                        <div className="token-info flex flex-row items-center justify-start">
                          <div className="tokan-icon mr-2"><img src="images/bcoin.svg" alt="" /></div>
                          <div className="tokan-info">
                            <div className="text-black dark:text-white text-sm font-semibold">USD Coin</div>
                            <div className="text-black dark:text-white text-opacity-50 text-xs">USDC</div>
                          </div>
                        </div>
                        <div className="flex flex-row items-center justify-end">
                          <div className="text-black dark:text-white text-opacity-50 text-2sm mr-2">0</div>
                        </div>
                      </a>
                      <a href="#" className="tokens-list-item p-3 flex flex-row items-center justify-between hover:bg-black hover:bg-opacity-5 dark:hover:bg-white dark:hover:bg-opacity-25">
                        <div className="token-info flex flex-row items-center justify-start">
                          <div className="tokan-icon mr-2"><img src="images/bcoin.svg" alt="" /></div>
                          <div className="tokan-info">
                            <div className="text-black dark:text-white text-sm font-semibold">USD Coin</div>
                            <div className="text-black dark:text-white text-opacity-50 text-xs">USDC</div>
                          </div>
                        </div>
                        <div className="flex flex-row items-center justify-end">
                          <div className="text-black dark:text-white text-opacity-50 text-2sm mr-2">0</div>
                        </div>
                      </a>
                      <a href="#" className="tokens-list-item p-3 flex flex-row items-center justify-between hover:bg-black hover:bg-opacity-5 dark:hover:bg-white dark:hover:bg-opacity-25">
                        <div className="token-info flex flex-row items-center justify-start">
                          <div className="tokan-icon mr-2"><img src="images/bcoin.svg" alt="" /></div>
                          <div className="tokan-info">
                            <div className="text-black dark:text-white text-sm font-semibold">USD Coin</div>
                            <div className="text-black dark:text-white text-opacity-50 text-xs">USDC</div>
                          </div>
                        </div>
                        <div className="flex flex-row items-center justify-end">
                          <div className="text-black dark:text-white text-opacity-50 text-2sm mr-2">0</div>
                        </div>
                      </a>
                      <a href="#" className="tokens-list-item p-3 flex flex-row items-center justify-between hover:bg-black hover:bg-opacity-5 dark:hover:bg-white dark:hover:bg-opacity-25">
                        <div className="token-info flex flex-row items-center justify-start">
                          <div className="tokan-icon mr-2"><img src="images/bcoin.svg" alt="" /></div>
                          <div className="tokan-info">
                            <div className="text-black dark:text-white text-sm font-semibold">USD Coin</div>
                            <div className="text-black dark:text-white text-opacity-50 text-xs">USDC</div>
                          </div>
                        </div>
                        <div className="flex flex-row items-center justify-end">
                          <div className="text-black dark:text-white text-opacity-50 text-2sm mr-2">0</div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

// const script = document.createElement("script"); 
// script.async = true;
// script.src = "https://s3.tradingview.com/tv.js";
// script.onload = () => this.scriptLoaded();

export default Dashboard;
