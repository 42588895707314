export const PAGE_TITLE = 'Metaple Finance - Decentralized Finance on Binance Smart Chain'
export const APP_NAME = 'METAPLE Finance'
export const TOKEN_NAME = 'METAPLE'
export const TOKEN_SYMBOL = 'MLX'

export const WHITEPAPER = 'https://metaplefinance.gitbook.io/metaple-finance/'
export const GITHUB = 'https://github.com/MetapleFinance'
export const TWITTER = 'https://twitter.com/MetapleFinance'
export const YOUTUBE = 'https://www.youtube.com/channel/UCfQen7asIoWe_GLDtz_hQ7w'
export const FACEBOOK = 'https://www.facebook.com/MetapleFinance'
export const BITCOIN = 'https://bitcointalk.org/index.php?action=profile;u=3378345'
export const MEDIUM = 'https://medium.com/@metaplefinance'
export const TELEGRAM = 'https://t.me/MetapleFinance'
export const REDDIT = 'https://www.reddit.com/r/Metaplefinance'

export default PAGE_TITLE