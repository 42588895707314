import { useMemo } from 'react';

import { ChainId, WETH } from '@udegoswap/sdk';
import abi from '@uniswap/v2-core/build/IUniswapV2Pair.json';
import {
    getBep20Contract,
    getUDEContract,
    getMasterchefContract,
    getErc721Contract,
    getSouschefContract
} from '../utils/contractHelpers';
import { getMulticallAddress } from '../utils/addressHelpers';
import useActiveWeb3React from "./useWeb3";
import ENS_PUBLIC_RESOLVER_ABI from '../config/abi/ens-public-resolver.json';
import ENS_ABI from '../config/abi/ens-registrar.json';
// eslint-disable-next-line import/extensions
import { ERC20_BYTES32_ABI } from '../config/abi/erc20';
import ERC20_ABI from '../config/abi/bep20.json';
import WETH_ABI from '../config/abi/weth.json';
import multiCallAbi from '../config/abi/multicall.json';
import { getContract } from '../utils';
/**
 * Helper hooks to get specific contracts (by ABI)
 */

export const useERC20 = (address) => {
    const { library } = useActiveWeb3React();
    return useMemo(() => getBep20Contract(address, library.getSigner()), [address, library]);
};
/**
 * @see https://docs.openzeppelin.com/contracts/3.x/api/token/erc721
 */
export const useERC721 = (address) => {
    const { library } = useActiveWeb3React();
    return useMemo(() => getErc721Contract(address, library.getSigner()), [address, library]);
};
export const useUDE = () => {
    const { library } = useActiveWeb3React();
    return useMemo(() => getUDEContract(library.getSigner()._address ? library.getSigner() : null), [library]);
};

export const useMasterchef = () => {
    const { library } = useActiveWeb3React();
    return useMemo(() => getMasterchefContract(library.getSigner()), [library]);
};

function useContract(address, ABI, withSignerIfPossible = true) {
    const { library, account } = useActiveWeb3React();
    return useMemo(() => {
        if (!address || !ABI || !library)
            return null;
        try {
            return getContract(address, ABI, library, withSignerIfPossible && account ? account : undefined);
        }
        catch (error) {
            console.error('Failed to get contract', error);
            return null;
        }
    }, [address, ABI, library, withSignerIfPossible, account]);
}
export function useTokenContract(tokenAddress, withSignerIfPossible) {
    return useContract(tokenAddress, ERC20_ABI, withSignerIfPossible);
}
export function useWETHContract(withSignerIfPossible) {
    const { chainId } = useActiveWeb3React();
    return useContract(chainId ? WETH[chainId].address : undefined, WETH_ABI, withSignerIfPossible);
}

export const useSousChef = (id) => {
    const { library } = useActiveWeb3React()
    return useMemo(() => getSouschefContract(id, library.getSigner()), [id, library])
}

export function useENSRegistrarContract(withSignerIfPossible) {
    const { chainId } = useActiveWeb3React();
    let address;
    if (chainId) {
        // eslint-disable-next-line default-case
        switch (chainId) {
            case ChainId.MAINNET:
            case ChainId.TESTNET:
                address = '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e';
                break;
        }
    }
    return useContract(address, ENS_ABI, withSignerIfPossible);
}
export function useENSResolverContract(address, withSignerIfPossible) {
    return useContract(address, ENS_PUBLIC_RESOLVER_ABI, withSignerIfPossible);
}
export function useBytes32TokenContract(tokenAddress, withSignerIfPossible) {
    return useContract(tokenAddress, ERC20_BYTES32_ABI, withSignerIfPossible);
}
export function usePairContract(pairAddress, withSignerIfPossible) {
    return useContract(pairAddress, abi, withSignerIfPossible);
}
export function useMulticallContract() {
    return useContract(getMulticallAddress(), multiCallAbi, false);
}