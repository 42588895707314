import "./App.css";
// importing components from react-router-dom package

import React, { Component }  from 'react';
import useAuth from './hooks/useAuth';
// import Home component

import {usePollBlockNumber} from "./state/block/hooks";
import useEagerConnect from "./hooks/useEagerConnect";
import useUserAgent from "./hooks/useUserAgent";
import {usePollCoreFarmData} from "./state/farms/hooks";
import Router from './routes';

function App() {
	const { isInitialized } = useAuth();
    usePollBlockNumber()
    useEagerConnect()
    useUserAgent()
    usePollCoreFarmData()

	return (
		<>
			<Router />
		</>
	);
}

export default App;
