export const config = {
    TOKEN_NAME: "METAPLE",
    TOKEN_SYMBOL: "MLX",
}

export const rpcUrl = {
    '0x89' : "https://rpc-mainnet.maticvigil.com/",
    '0x13881' : "https://rpc-mainnet.maticvigil.com/",
    '0x38' : "https://bsc-dataseed.binance.org/",
    '0x61' : 'https://data-seed-prebsc-2-s1.binance.org:8545/'
}

export const PC_ROUTER_ADDRESS = '0xa4c7928E1B6EcB8b3464EE875EaC66571C3F2f06';
export const ROUTER_ADDRESS = '0xa4c7928E1B6EcB8b3464EE875EaC66571C3F2f06'
export const UDE_ROUTER = '0xa4c7928E1B6EcB8b3464EE875EaC66571C3F2f06'

export const DEFAULT_NETWORK = '0x61'
export const WALLET_ADDRESS = 'wallet_address'
export const TRANSACTIONS   = 'transactions'
export const REFER_LINK = 'https://dapp.metaple.finance/home?referLink='

export const ERROR_PROP = {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
}

export const SUCCESS_PROP = {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
}

export const INFO_PROP = {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
}

export const POOLS_URL = "http://localhost:8082/"
export const MIN_PRICE_IMPACT = 0.0001
export const SWAP_IN = 0
export const SWAP_OUT = 1

export const PENDING_MESSAGE = 'Transaction Submitted...'
export const ERROR_MESSAGE = 'Error while handling Transaction!!'
export const SUCCESS_MESSAGE = 'Transaction Confirmed!!'
export const ISWC = "IS_WALLET_CONNECT"
export const WCHAIN = 'WALLET_CONNECT_CHAIN_ID'

export const BASE_URL = 'https://dapp.metaple.finance/'
export const SWAP_SOUND = './swap.mp3'