import BigNumber from 'bignumber.js';
import poolsConfig from '../../config/constants/pools';
import sousChefABI from '../../config/abi/sousChef.json';
import masterChef from '../../config/abi/masterchef.json';
import erc20ABI from '../../config/abi/erc20.json';
import multicall from '../../utils/multicall';
import { getMasterchefContract } from '../../utils/contractHelpers';
import { getAddress } from '../../utils/addressHelpers';
import { simpleRpcProvider } from '../../utils/providers';
// Pool 0, UDE / UDE is a different kind of contract (master chef)
// BNB pools use the native BNB token (wrapping ? unwrapping is done at the contract level)
const nonBnbPools = poolsConfig.filter((pool) => pool.stakingToken.symbol !== 'BNB');
const bnbPools = poolsConfig.filter((pool) => pool.stakingToken.symbol === 'BNB');
const nonMasterPools = poolsConfig.filter((pool) => (pool.sousId !== 0 && pool.sousId !== 1 && pool.sousId !== 4));
const masterChefContract = getMasterchefContract();
export const fetchPoolsAllowance = async (account) => {
    const calls = nonBnbPools.map((pool) => ({
        address: pool.stakingToken.address,
        name: 'allowance',
        params: [account, getAddress(pool.contractAddress)],
    }));
    const allowances = await multicall(erc20ABI, calls);
    return nonBnbPools.reduce((acc, pool, index) => ({...acc, [pool.sousId]: new BigNumber(allowances[index] ?? "0").toJSON()}), {});
};
export const fetchUserBalances = async (account) => {
    // Non BNB pools
    const calls = nonBnbPools.map((pool) => ({
        address: pool.stakingToken.address,
        name: 'balanceOf',
        params: [account],
    }));
    const tokenBalancesRaw = await multicall(erc20ABI, calls);
    const tokenBalances = nonBnbPools.reduce((acc, pool, index) => ({...acc, [pool.sousId]: new BigNumber(tokenBalancesRaw[index] ?? "0").toJSON()}), {});
    // BNB pools
    const bnbBalance = await simpleRpcProvider.getBalance(account);
    const bnbBalances = bnbPools.reduce((acc, pool) => ({...acc, [pool.sousId]: new BigNumber(bnbBalance.toString()).toJSON()}), {});
    return {...tokenBalances, ...bnbBalances};
};

export const fetchUserLastInvested = async (account) => {
    const calls = nonMasterPools.map((p) => ({
        address: getAddress(p.contractAddress),
        name: 'userInfo',
        params: [p.sousId, account],
    }));
    const userInfo = await multicall(masterChef, calls);
    const stakedBalances = nonMasterPools.reduce((acc, pool, index) => ({...acc, [pool.sousId]: new BigNumber(userInfo[index].amount._hex).toJSON()}), {});
    // UDE / UDE pool
    const _user1 = await masterChefContract.userInfo('0', account);
    const _user2 = await masterChefContract.userInfo('1', account);
    const _user3 = await masterChefContract.userInfo('4', account);
    return {...stakedBalances,
        0: _user1._lastInvested,
        1: _user2._lastInvested,
        4: _user3._lastInvested,
    };
};

export const fetchUserStakeBalances = async (account) => {
    // // console.log(nonMasterPools)
    const calls = nonMasterPools.map((p) => ({
        address: getAddress(p.contractAddress),
        name: 'userInfo',
        params: [p.sousId, account],
    }));
    const userInfo = await multicall(masterChef, calls);
    const stakedBalances = nonMasterPools.reduce((acc, pool, index) => ({...acc, [pool.sousId]: new BigNumber(userInfo[index].amount._hex).toJSON()}), {});
    // UDE / UDE pool
    const { amount: masterPoolAmount } = await masterChefContract.userInfo('0', account);
    const { amount: masterPoolAmount2 } = await masterChefContract.userInfo('1', account);
    const { amount: masterPoolAmount3 } = await masterChefContract.userInfo('4', account);
    return {...stakedBalances,
        0: new BigNumber(masterPoolAmount.toString()).toJSON(),
        1: new BigNumber(masterPoolAmount2.toString()).toJSON(),
        4: new BigNumber(masterPoolAmount3.toString()).toJSON(),
    };
};
export const fetchUserPendingRewards = async (account) => {

    const calls = nonMasterPools.map((p) => ({
        address: getAddress(p.contractAddress),
        name: 'pendingMLX',
        params: [p.sousId, account],
    }));
    const res = await multicall(masterChef, calls);
    const pendingRewards = nonMasterPools.reduce((acc, pool, index) => ({...acc, [pool.sousId]: new BigNumber(res[index]).toJSON()}), {});
    // UDE / UDE pool
    const pendingReward = await masterChefContract.pendingMLX('0', account);
    const pendingReward2 = await masterChefContract.pendingMLX('1', account);
    const pendingReward3 = await masterChefContract.pendingMLX('4', account);
    return {...pendingRewards,
        0: new BigNumber(pendingReward.toString()).toJSON(),
        1: new BigNumber(pendingReward2.toString()).toJSON(),
        4: new BigNumber(pendingReward3.toString()).toJSON()
    };
};
