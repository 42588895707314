import { serializeTokens } from '../tokens'

const serializedTokens = serializeTokens()

const farms = [
    /**
     * These 3 farms (PID 0, 251, 252) should always be at the top of the file.
     */
    {
        pid: 0,
        lpSymbol: 'MLX',
        lpAddresses: {
            97: '0xeBDA6aaE3f8c96eAEbe33cEEbbAE24Fc39F315d6',
            56: '0xeBDA6aaE3f8c96eAEbe33cEEbbAE24Fc39F315d6',
        },
        token: serializedTokens.mlxpos,
        quoteToken: serializedTokens.wbnb,
    },
    {
        pid: 2,
        lpSymbol: 'MLX-BNB LP',
        lpAddresses: {
            97: '0xAC22A4937bC5DD4d9b3Bee0c4DF02Fee8d6bd787',
            56: '0x70949682f4322233bfa0b4affdee7a1a1143352a',
        },
        token: serializedTokens.mlx,
        quoteToken: serializedTokens.wbnb,
    },
    {
        pid: 3,
        lpSymbol: 'MLX-BUSD LP',
        lpAddresses: {
            97: '0x4e85d100ae33bb8f1ce5d4706d469eb94f17b3bf',
            56: '0x560A2FddC01B29DD19b426fd76098892cf7b6beb',
        },
        token: serializedTokens.mlx,
        quoteToken: serializedTokens.busd,
    }
    // {
    //     pid: 4,
    //     lpSymbol: 'MLX-CAKE LP',
    //     lpAddresses: {
    //         97: '0x4e85d100ae33bb8f1ce5d4706d469eb94f17b3bf',
    //         56: '0xf0fc84853911e500fa6d51fc3076211a3ff16d46',
    //     },
    //     token: serializedTokens.mlx,
    //     quoteToken: serializedTokens.cake,
    // },
    // {
    //     pid: 5,
    //     lpSymbol: 'MLX-BAKE LP',
    //     lpAddresses: {
    //         97: '0x4e85d100ae33bb8f1ce5d4706d469eb94f17b3bf',
    //         56: '0xe9dd45013dfc0ce65857facda0c62604a8ed7de9',
    //     },
    //     token: serializedTokens.mlx,
    //     quoteToken: serializedTokens.bake,
    // }
]

export default farms
