import {ethers} from "ethers";
import {toast} from "react-toastify";
import {Web3Provider} from "@ethersproject/providers";
import Web3 from "web3";
import {FACTORY_ADDRESS} from "@udegoswap/sdk";
import {
    DEFAULT_NETWORK,
    ERROR_MESSAGE,
    ERROR_PROP,
    PENDING_MESSAGE,
    rpcUrl,
    SUCCESS_MESSAGE,
    SUCCESS_PROP,
    TRANSACTIONS,
    WALLET_ADDRESS
} from "./config";
import tokenAbi, {factoryABI, pairABI} from "./abis";
import tokens from "./tokens";
import { PAN_FACT } from "./constants";

export const network = () => {
    // const connector = getConnector()
    // if(connector && explorers[`0x${connector.chainId.toString(16)}`]){
    //     toReturn = `0x${connector.chainId.toString(16)}`
    // }
    return (window.ethereum && window.ethereum.chainId) ?? DEFAULT_NETWORK;
}

export const web3Provider = () => {
    return new Web3Provider(window.ethereum ?? new Web3.providers.HttpProvider(rpcUrl[network()]));
}

export const getSigner = () => {
    // const connector = getConnector()
    // if(connector.connected){
    //     signer = new ethers.providers.Web3Provider(getWalletConnectProvider())
    // }
    // // console.log(signer)

    return (window.ethereum && window.ethereum.selectedAddress) ? (new ethers.providers.Web3Provider(window.ethereum)).getSigner()
        : (new ethers.providers.Web3Provider(new Web3.providers.HttpProvider(rpcUrl["0x38"])));
}

export const getContract = (address, abi=tokenAbi, signer=null) => {
    /* eslint no-underscore-dangle: 0 */
    const signerOrProvider = (signer && signer._address) ? signer : getSigner()
    const contract = new ethers.Contract(address, abi, signerOrProvider)
    return contract
}

export const showError = (message) => toast.error(message, ERROR_PROP)
export const showFulfill = (message) => toast.success(message, SUCCESS_PROP)

export const showPromise = (promise, pendingMessage=PENDING_MESSAGE, errorMessage=ERROR_MESSAGE, successMessage=SUCCESS_MESSAGE, callback=null) => {
    toast.promise(
        promise,
        {
            pending: PENDING_MESSAGE,
            success: SUCCESS_MESSAGE,
            error: ERROR_MESSAGE
        },
        SUCCESS_PROP
    )
}

export const approveToken = async (contract, spender) => {
    try {
        const address = await localStorage.getItem(WALLET_ADDRESS)
        const txn = await contract.approve(spender, ethers.constants.MaxUint256)

        showPromise(
            txn.wait(),
            "Approving Transaction...",
            "Approved Failed!!",
            "Transaction Approved Successfully!!"
        )
        return txn
    } catch (e) {
        // console.log(e)
        showError(e.data && e.data !== null ? e.data.message : e.message)
        return null
    }
}

export const initReferralCode = async (referral, account) => {
    try{
        const token = await getContract(tokens.mlx.address)
        if(account && account !== '' && referral && referral !== ''){
            const referrer = await token.referrer(account)
            if(referral !== account){
                if(referrer === ethers.constants.AddressZero){
                    const txn = await token.initReferral(referral)
                    showPromise(txn.wait())
                }
            }else{
                showError("Cannot be same referrer!!")
            }
        }
    }catch (e) {
        // console.log(e)
    }
}

export const getDeadline = (deadline=20) => (Math.floor(Date.now() / 1000) * 60*deadline)

export const updateRecents = async (data) => {
    try{
        const txns = await localStorage.getItem(TRANSACTIONS)
        const jsD = (txns && JSON.parse(txns)) ?? []
        jsD.push(data)
        await localStorage.setItem(TRANSACTIONS, JSON.stringify(jsD))
    }catch (e) {
        // console.log(e)
    }
}

export const copyText = (text) => {
    try{
        navigator.clipboard.writeText(text)
        toast.success('Copied Successfully!', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    } catch (e) {
        showError(`Could Not Copy To Clipboard - ${e}`)
    }
}

export const getMin = (value, minSlippage=0.1) => parseFloat((value - (value * minSlippage / 100)).toLocaleString()).toFixed().replaceAll(",", '')

export const getPrice = async (token, token_second=tokens.wbnb.address) => {
    try{
        let returned = 0;
        const factory = await getContract(FACTORY_ADDRESS, factoryABI)
        let pairContract = await factory.getPair(token, token_second)
        if(pairContract === ethers.constants.AddressZero) {
            const pancakeFactory = await getContract(PAN_FACT, factoryABI)
            pairContract = await pancakeFactory.getPair(token, token_second)
        }
        if(pairContract !== ethers.constants.AddressZero) {
            const pairC = await getContract(pairContract, pairABI)
            const token0 = await pairC.token0();

            const reserves = await pairC.getReserves();
            const res0 = reserves[0]
            const res1 = reserves[1];
            if (token0.toLowerCase() === token.toLowerCase()) {
                returned = (res1 / res0)
            } else {
                returned = (res0 / res1);
            }
            return returned;
        }
        return 0;
    }catch (e) {
        // console.log(e)
        return 0
    }
}

export const getBNBPrice = async () => {
    try{
        const returned = await getPrice(tokens.wbnb.address, tokens.busd.address)
        return returned;
    }catch (e) {
        // console.log(e)
        return 0;
    }
}

export const getTokenPrice = async (token, secondToken=tokens.wbnb.address) => {
    try{
        const bnbPrice = await getBNBPrice()
        if(token.toLowerCase() === tokens.wbnb.address.toLowerCase()){
            return bnbPrice;
        }
        if(token.toLowerCase() === tokens.mlxpos.address.toLowerCase()){
            token = tokens.mlx.address
        }
        // console.log(bnbPrice)
        const tokenPrice = await getPrice(token, secondToken);
        return secondToken === tokens.busd.address ? tokenPrice : (tokenPrice * bnbPrice);
    }catch (e) {
        return 0;
    }
}

export const getLPPrice = async (poolAddress) => {
    try{
        const pairC = await getContract(poolAddress, pairABI)
        const token0 = await pairC.token0()
        const token1 = await pairC.token1()

        const balances = await pairC.getReserves()
        const totalSupply = await pairC.totalSupply()

        let poolValue = 0
        /* eslint-disable no-await-in-loop */
        const price0 = await getTokenPrice(token0)
        const price1 = await getTokenPrice(token1)
        const tokenBal0 = balances.reserve0 * price0
        const tokenBal1 = balances.reserve1 * price1

        poolValue = tokenBal0+tokenBal1
        const lpPrice = poolValue/totalSupply
        return lpPrice;
    }catch (e) {
        // console.log(e)
        return 0
    }
}
