import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from 'axios';

// ----------------------------------------------------------------------

const initialState = {
    isLoading: false,
    error: false,
    tokens: [],
    pooltokens: []
};

const slice = createSlice({
    name: 'tokens',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // GET Tokens
        getTokensSuccess(state, action) {
            state.isLoading = false;
            state.tokens = action.payload;
        },

        // GET Pool Tokens
        getPoolTokenSuccess(state, action) {
            state.isLoading = false;
            state.pooltokens = action.payload
        }
    }
});

// Reducer
export default slice.reducer;

// Actions
export const { onToggleFollow, deleteUser } = slice.actions;

export function getTokens() {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get('/data/allTokens.json', {
                responseType: "json"
            });
            const tokens = response.data
            dispatch(slice.actions.getTokensSuccess(tokens));
        } catch (error) {
            // console.log(error)
            dispatch(slice.actions.hasError(error));
        }
    };
}


export function getPoolTokens() {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get('/data/allPoolTokens.json?updated=192923', {
                responseType: "json"
            });
            const tokens = response.data
            dispatch(slice.actions.getPoolTokenSuccess(tokens));
        } catch (error) {
            // console.log(error)
            dispatch(slice.actions.hasError(error));
        }
    };
}
