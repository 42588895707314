import { ethers } from 'ethers';
import Web3 from "web3";
import { simpleRpcProvider } from './providers';
import { poolsConfig }  from '../config/constants/index'
// Addresses
import {getAddress, getMasterChefAddress, getMulticallAddress} from './addressHelpers';
import bep20Abi from '../config/abi/erc20.json';
import erc721Abi from '../config/abi/erc721.json';
import lpTokenAbi from '../config/abi/lpToken.json';
import masterChef from '../config/abi/masterchef.json';
import udeAbi from '../config/abi/ude.json'
import tokens from "../config/tokens";
import MultiCallAbi from '../config/abi/multicall.json';
import {PoolCategory} from "../config/constants/types";

import sousChef from '../config/abi/sousChef.json'
import sousChefBnb from '../config/abi/sousChefBnb.json'

const globalWeb3 = new Web3(Web3.givenProvider || 'https://bsc-dataseed1.binance.org/')

const getSigner = () => {
    const signer = (new ethers.providers.Web3Provider(globalWeb3.currentProvider));
    return signer
}

const getContract = (abi, address, signer = null) => {
    const signerOrProvider = signer ?? getSigner()
    return new ethers.Contract(address, abi, signerOrProvider)
}

export const getBep20Contract = (address, signer) => getContract(bep20Abi, address, signer);

export const getErc721Contract = (address, signer) => getContract(erc721Abi, address, signer);

export const getLpContract = (address, signer) => getContract(lpTokenAbi, address, signer);

export const getUDEContract = (signer) => getContract(udeAbi, tokens.mlx.address, signer);

export const getMulticallContract = (signer) => getContract(MultiCallAbi, getMulticallAddress(), signer)

export const getMasterchefContract = (signer) => getContract(masterChef, getMasterChefAddress(), signer);

export const getSouschefContract = (id, signer) => {
    const config = poolsConfig.find((pool) => pool.sousId === id)
    const abi = config.poolCategory === PoolCategory.BINANCE ? sousChefBnb : sousChef
    return getContract(abi, getAddress(config.contractAddress), signer)
}
