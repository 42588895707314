const PANCAKE_EXTENDED = 'https://tokens.pancakeswap.finance/pancakeswap-extended.json';
const PANCAKE_TOP100 = 'https://tokens.pancakeswap.finance/pancakeswap-top-100.json';
const UDE_LIST = 'https://dapp.metaple.finance/data/allMLXTokens.json'
export const UNSUPPORTED_LIST_URLS = [];
// lower index == higher priority for token import
export const DEFAULT_LIST_OF_LISTS = [
    PANCAKE_TOP100,
    PANCAKE_EXTENDED,
    UDE_LIST,
    ...UNSUPPORTED_LIST_URLS, // need to load unsupported tokens as well
];
// default lists to be 'active' aka searched across
export const DEFAULT_ACTIVE_LIST_URLS = [];
