import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useWeb3React } from '@web3-react/core';
import BigNumber from 'bignumber.js';
import { useAppDispatch } from "../../redux/store";
import { BIG_ZERO } from '../../utils/bigNumber';
import { getBalanceAmount } from '../../utils/formatBalance';
import { farmsConfig } from '../../config/constants/index';
import useRefresh from '../../hooks/useRefresh';
import { deserializeToken } from '../user/hooks/helpers';
import { fetchFarmsPublicDataAsync, fetchFarmUserDataAsync, nonArchivedFarms } from '.';

const deserializeFarmUserData = (farm) => ({
        allowance: farm.userData ? new BigNumber(farm.userData.allowance) : BIG_ZERO,
        tokenBalance: farm.userData ? new BigNumber(farm.userData.tokenBalance) : BIG_ZERO,
        stakedBalance: farm.userData ? new BigNumber(farm.userData.stakedBalance) : BIG_ZERO,
        earnings: farm.userData ? new BigNumber(farm.userData.earnings) : BIG_ZERO,
        userLast: farm.userData ? farm.userData.userLast : BIG_ZERO,
    });
const deserializeFarm = (farm) => {
    const { lpAddresses, lpSymbol, pid, dual, multiplier, isCommunity, quoteTokenPriceBusd, tokenPriceBusd } = farm;
    return {
        lpAddresses,
        lpSymbol,
        pid,
        dual,
        multiplier,
        isCommunity,
        quoteTokenPriceBusd,
        tokenPriceBusd,
        token: deserializeToken(farm.token),
        quoteToken: deserializeToken(farm.quoteToken),
        userData: deserializeFarmUserData(farm),
        tokenAmountTotal: farm.tokenAmountTotal ? new BigNumber(farm.tokenAmountTotal) : BIG_ZERO,
        lpTotalInQuoteToken: farm.lpTotalInQuoteToken ? new BigNumber(farm.lpTotalInQuoteToken) : BIG_ZERO,
        lpTotalSupply: farm.lpTotalSupply ? new BigNumber(farm.lpTotalSupply) : BIG_ZERO,
        tokenPriceVsQuote: farm.tokenPriceVsQuote ? new BigNumber(farm.tokenPriceVsQuote) : BIG_ZERO,
        poolWeight: farm.poolWeight ? new BigNumber(farm.poolWeight) : BIG_ZERO,
    };
};
export const usePollFarmsPublicData = (includeArchive = false) => {
    const dispatch = useAppDispatch();
    const { slowRefresh } = useRefresh();
    useEffect(() => {
        const farmsToFetch = includeArchive ? farmsConfig : nonArchivedFarms;
        const pids = farmsToFetch.map((farmToFetch) => farmToFetch.pid);
        dispatch(fetchFarmsPublicDataAsync(pids));
    }, [includeArchive, dispatch, slowRefresh]);
};
export const usePollFarmsWithUserData = (includeArchive = false) => {
    const dispatch = useAppDispatch();
    const { slowRefresh } = useRefresh();
    const { account } = useWeb3React();
    useEffect(() => {
        const farmsToFetch = includeArchive ? farmsConfig : nonArchivedFarms;
        const pids = farmsToFetch.map((farmToFetch) => farmToFetch.pid);
        dispatch(fetchFarmsPublicDataAsync(pids));
        if (account) {
            dispatch(fetchFarmUserDataAsync({ account, pids }));
        }
    }, [includeArchive, dispatch, slowRefresh, account]);
};
/**
 * Fetches the "core" farm data used globally
 * 251 = CAKE-BNB LP
 * 252 = BUSD-BNB LP
 */
export const usePollCoreFarmData = () => {
    const dispatch = useAppDispatch();
    const { fastRefresh } = useRefresh();
    useEffect(() => {
        dispatch(fetchFarmsPublicDataAsync([2, 3, 4]));
    }, [dispatch, fastRefresh]);
};
export const useFarms = () => {
    const farms = useSelector((state) => state.farms);
    const deserializedFarmsData = farms.data.map(deserializeFarm);
    const { loadArchivedFarmsData, userDataLoaded } = farms;
    return {
        loadArchivedFarmsData,
        userDataLoaded,
        data: deserializedFarmsData,
    };
};
export const useFarmFromPid = (pid) => {
    const farm = useSelector((state) => state.farms.data.find((f) => f.pid === pid));
    return deserializeFarm(farm);
};
export const useFarmFromLpSymbol = (lpSymbol) => {
    const farm = useSelector((state) => state.farms.data.find((f) => f.lpSymbol === lpSymbol));
    return deserializeFarm(farm);
};
export const useFarmUser = (pid) => {
    const { userData } = useFarmFromPid(pid);
    const { allowance, tokenBalance, stakedBalance, earnings } = userData;
    return {
        allowance,
        tokenBalance,
        stakedBalance,
        earnings,
    };
};
// Return the base token price for a farm, from a given pid
export const useBusdPriceFromPid = (pid) => {
    const farm = useFarmFromPid(pid);
    return farm && new BigNumber(farm.tokenPriceBusd);
};
export const useLpTokenPrice = (symbol) => {
    const farm = useFarmFromLpSymbol(symbol);
    const farmTokenPriceInUsd = useBusdPriceFromPid(farm.pid);
    let lpTokenPrice = BIG_ZERO;
    if (farm.lpTotalSupply.gt(0) && farm.lpTotalInQuoteToken.gt(0)) {
        // Total value of base token in LP
        const valueOfBaseTokenInFarm = farmTokenPriceInUsd.times(farm.tokenAmountTotal);
        // Double it to get overall value in LP
        const overallValueOfAllTokensInFarm = valueOfBaseTokenInFarm.times(2);
        // Divide total value of all tokens, by the number of LP tokens
        const totalLpTokens = getBalanceAmount(farm.lpTotalSupply);
        lpTokenPrice = overallValueOfAllTokensInFarm.div(totalLpTokens);
    }
    return lpTokenPrice;
};
// /!\ Deprecated , use the BUSD hook in /hooks
export const usePriceUdeBusd = () => {
    // const udeBnbFarm = useFarmFromPid(2);
    // const udePriceBusdAsString = udeBnbFarm.tokenPriceBusd;
    // const udePriceBusd = useMemo(() => new BigNumber(udePriceBusdAsString), [udePriceBusdAsString]);
    return 1;
};