import { createSlice } from '@reduxjs/toolkit';
import {WALLET_CONNECTED} from "../../config/constants";

// ----------------------------------------------------------------------

const initialState = {
    isLoading: false,
    error: false,
    account: "",
    balances: [],
    stakes: []
};

const slice = createSlice({
    name: 'wallet_profile',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // GET Address
        getAddress(state, action) {
            state.isLoading = false;
            state.account = action.payload;
        },

        // GET Balances
        getBalance(state, action) {
            state.isLoading = false;
            state.balances = action.payload;
        }
    }
});

// Reducer
export default slice.reducer;

export function getWallet() {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            const address = await localStorage.getItem(WALLET_CONNECTED)
            const account = address
            dispatch(slice.actions.getAddress(account));
        } catch (error) {
            // console.log(error)
            dispatch(slice.actions.hasError(error));
        }
    };
}
