import {ALL_TOKENS} from "../contexts/TokenList";

export const appConstants = {
    symbol: 'MLX',
    name: 'METAPLE',
    token: ALL_TOKENS.mlx
}

export const WALLET_CONNECTED = 'walletConnected'
export const EXPLORER = 'https://bscscan.com/'
export const INITIAL_ALLOWED_SLIPPAGE = 50
export const DEFAULT_DEADLINE_FROM_NOW = 60 * 20
export const FARMING = '0x352391191df92259BB6eCbc9043c98bc50F5A389'

export const PAN_FACT = "0xcA143Ce32Fe78f1f7019d7d551a6402fC5350c73";