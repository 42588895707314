import { serializeTokens } from '../tokens';
import { PoolCategory } from './types';

const serializedTokens = serializeTokens();
const pools = [
    {
        sousId: 0,
        stakingToken: serializedTokens.mlx,
        earningToken: serializedTokens.mlx,
        contractAddress: {
            97: '0x5E6AE253283ff3f143a2537A2C6B1221B72A9524',
            56: '0x352391191df92259BB6eCbc9043c98bc50F5A389',
        },
        poolCategory: PoolCategory.CORE,
        harvest: true,
        tokenPerBlock: '0.38',
        sortOrder: 1,
        isFinished: false,
    },
    {
        sousId: 1,
        stakingToken: serializedTokens.mlxpos,
        earningToken: serializedTokens.mlx,
        contractAddress: {
            97: '0x5E6AE253283ff3f143a2537A2C6B1221B72A9524',
            56: '0x352391191df92259BB6eCbc9043c98bc50F5A389',
        },
        poolCategory: PoolCategory.CORE,
        harvest: true,
        sortOrder: 999,
        tokenPerBlock: '0.01',
    },
    // {
    //     sousId: 6,
    //     stakingToken: serializedTokens.busd,
    //     earningToken: serializedTokens.mlx,
    //     contractAddress: {
    //         97: '0x5E6AE253283ff3f143a2537A2C6B1221B72A9524',
    //         56: '0x352391191df92259BB6eCbc9043c98bc50F5A389',
    //     },
    //     poolCategory: PoolCategory.CORE,
    //     harvest: true,
    //     sortOrder: 999,
    //     tokenPerBlock: '0.01',
    // },
    // {
    //     sousId: 7,
    //     stakingToken: serializedTokens.cake,
    //     earningToken: serializedTokens.mlx,
    //     contractAddress: {
    //         97: '',
    //         56: '0x352391191df92259BB6eCbc9043c98bc50F5A389',
    //     },
    //     poolCategory: PoolCategory.CORE,
    //     harvest: true,
    //     sortOrder: 999,
    //     tokenPerBlock: '0.01',
    // }
];
export default pools;