// import BigNumber from 'bignumber.js/bignumber'
import BigNumber from "bignumber.js";
import {BIG_TEN} from "../utils/bigNumber";

BigNumber.config({
    EXPONENTIAL_AT: 1000,
    DECIMAL_PLACES: 80,
})
export const BASE_BSC_SCAN_URLS = {
    56: 'https://bscscan.com',
    97: 'https://testnet.bscscan.com'
}
export const BASE_URL = 'https://dapp.metaple.finance/'
export const IPFS_GATEWAY = 'https://ipfs.io/ipfs'

export const BSC_BLOCK_TIME = 3.2

export const MLX_PER_BLOCK = 0.001
export const BLOCKS_PER_YEAR = (60 / BSC_BLOCK_TIME) * 60 * 24 * 365 // 10512000
export const UDE_PER_YEAR = MLX_PER_BLOCK * BLOCKS_PER_YEAR

export const DEFAULT_TOKEN_DECIMAL = BIG_TEN.pow(18)
export const DEFAULT_GAS_LIMIT = 200000

export const formatUnit = (val, fixed=4) => parseFloat(val / (10 ** 18)).toFixed(fixed)