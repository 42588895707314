import { Contract } from '@ethersproject/contracts';
import { getAddress } from '@ethersproject/address';
import { AddressZero } from '@ethersproject/constants';
import { BigNumber } from '@ethersproject/bignumber';

import IUniswapV2Router02ABI from '@uniswap/v2-periphery/build/IUniswapV2Router02.json';
import { ChainId, JSBI, Percent, ETHER } from '@udegoswap/sdk';
import { Token } from "@udegoswap/sdk";
import {PC_ROUTER_ADDRESS as ROUTER_ADDRESS, UDE_ROUTER} from '../config/config';
import { BASE_BSC_SCAN_URLS} from '../config/index' ;
// returns the checksummed address if the address is valid, otherwise returns false
export function isAddress(value) {
    try {
        return getAddress(value);
    }
    catch (_a) {
        return false;
    }
}
export function getBscScanLink(data, type, chainId = ChainId.MAINNET) {
    switch (type) {
        case 'transaction': {
            return `${BASE_BSC_SCAN_URLS[chainId]}/tx/${data}`;
        }
        case 'token': {
            return `${BASE_BSC_SCAN_URLS[chainId]}/token/${data}`;
        }
        case 'block': {
            return `${BASE_BSC_SCAN_URLS[chainId]}/block/${data}`;
        }
        case 'countdown': {
            return `${BASE_BSC_SCAN_URLS[chainId]}/block/countdown/${data}`;
        }
        default: {
            return `${BASE_BSC_SCAN_URLS[chainId]}/address/${data}`;
        }
    }
}
export function getBscScanLinkForNft(collectionAddress, tokenId, chainId = ChainId.MAINNET) {
    return `${BASE_BSC_SCAN_URLS[chainId]}/token/${collectionAddress}?a=${tokenId}`;
}
// add 10%
export function calculateGasMargin(value) {
    return value.mul(BigNumber.from(10000).add(BigNumber.from(1000))).div(BigNumber.from(10000));
}
// converts a basis points value to a sdk percent
export function basisPointsToPercent(num) {
    return new Percent(JSBI.BigInt(num), JSBI.BigInt(10000));
}
export function calculateSlippageAmount(value, slippage) {
    if (slippage < 0 || slippage > 10000) {
        throw Error(`Unexpected slippage value: ${slippage}`);
    }
    return [
        JSBI.divide(JSBI.multiply(value.raw, JSBI.BigInt(10000 - slippage)), JSBI.BigInt(10000)),
        JSBI.divide(JSBI.multiply(value.raw, JSBI.BigInt(10000 + slippage)), JSBI.BigInt(10000)),
    ];
}
// account is not optional
export function getSigner(library, account) {
    return library.getSigner(account).connectUnchecked();
}
// account is optional
export function getProviderOrSigner(library, account) {
    return account ? getSigner(library, account) : library;
}
// account is optional
export function getContract(address, ABI, library, account) {
    if (!isAddress(address) || address === AddressZero) {
        throw Error(`Invalid 'address' parameter '${address}'.`);
    }
    return new Contract(address, ABI, getProviderOrSigner(library, account));
}

export function getRouterContract(_, library, account) {
    return getContract(ROUTER_ADDRESS, IUniswapV2Router02ABI.abi, library, account);
}

// router for UDE
export function getUDERouterContract(_, library, account) {
    return getContract(UDE_ROUTER, IUniswapV2Router02ABI.abi, library, account);
}

export function escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}
export function isTokenOnList(defaultTokens, currency) {
    let _a;
    if (currency === ETHER)
        return true;
    // eslint-disable-next-line no-void,no-cond-assign
    return Boolean(currency instanceof Token && ((_a = defaultTokens[currency.chainId]) === null || _a === void 0 ? void 0 : _a[currency.address]));
}

export function isZero(hexNumberString) {
  return /^0x0*$/.test(hexNumberString)
}

export function lessThan(firstAmount, secondAmount) {
    return JSBI.lessThan(
        JSBI.multiply(firstAmount.numerator, secondAmount.denominator),
        JSBI.multiply(secondAmount.numerator, firstAmount.denominator)
    )
}