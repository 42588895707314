import logo from './logo.svg';
import { Link, Outlet } from "react-router-dom";
import './App.css';
import React, {useEffect, useState} from 'react';
import Button from "@material-tailwind/react/Button";
import useActiveWeb3React from "./hooks/useWeb3";
import {useLocation} from "react-router-dom";
import {initReferralCode} from "./config/helpers";


//Show and hide hamburguer menu in small screens 
// const menu = document.getElementById("menu");
// const ulMenu = document.getElementById("ulMenu");
// function menuToggle() {
//   // // console.log(menu);
// }
const useQuery= () => new URLSearchParams(useLocation().search);

function Sidebar() {
  const [isActive, setActive] = useState("false");
  const [isDark, setDark] = useState("false");
  const { account } = useActiveWeb3React()
  const query = useQuery()
  const referrer = query.get("referLink")

  useEffect(() => {
    if(referrer != null) {
      try{
        // // console.log(referrer)
        if(account && referrer && referrer.toLowerCase() !== account.toLowerCase()){
          initReferralCode(referrer, account)
        }
      }catch (e) {
        // console.log(e)
      }
    }
  }, [referrer, account])

  const handleToggle = () => {
    setActive(!isActive);
  };

  // useEffect(() => {
  //   document.body.classList.toggle('modal-open', isOpen);
  // },[isOpen])
  const darkToggle = () => {
    setDark(!isDark);
  };

  return (
    <>
      <nav className={`dark:bg-gradient-to-b dark:from-sky-800 dark:to-gray-900 ${isDark ? "" : "dark"}`}>
        <div className="lg:hidden py-6 px-6 bg-gray-800">
          <div className="flex items-center justify-between">
            <Link to="/" className="text-2xl text-white font-semibold">
              <div className="font-extrabold text-sky-700 dark:text-white text-3xl tracking-widest mt-2">
                <img src="images/metaple-icon.png" className='h-16'></img>
              </div>
            </Link>
              {/* Dark mode switcher */}
              {/* <button id="theme-toggle" type="button" className="text-sky-600 dark:text-gray-100 hover:bg-sky-200 dark:hover:bg-white dark:hover:bg-opacity-25 focus:outline-none focus:ring-0 focus:ring-gray-100 dark:focus:ring-gray-100 rounded-lg text-sm p-2 animate-bounce" onCLick={darkToggle}>
                <svg id="theme-toggle-dark-icon" className="w-5 h-5 hidden" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path d="M17.293 13.293A8 8 0 016.707 2.707a8.001 8.001 0 1010.586 10.586z" />
                </svg>
                <svg id="theme-toggle-light-icon" className="w-5 h-5 hidden" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10 2a1 1 0 011 1v1a1 1 0 11-2 0V3a1 1 0 011-1zm4 8a4 4 0 11-8 0 4 4 0 018 0zm-.464 4.95l.707.707a1 1 0 001.414-1.414l-.707-.707a1 1 0 00-1.414 1.414zm2.12-10.607a1 1 0 010 1.414l-.706.707a1 1 0 11-1.414-1.414l.707-.707a1 1 0 011.414 0zM17 11a1 1 0 100-2h-1a1 1 0 100 2h1zm-7 4a1 1 0 011 1v1a1 1 0 11-2 0v-1a1 1 0 011-1zM5.05 6.464A1 1 0 106.465 5.05l-.708-.707a1 1 0 00-1.414 1.414l.707.707zm1.414 8.486l-.707.707a1 1 0 01-1.414-1.414l.707-.707a1 1 0 011.414 1.414zM4 11a1 1 0 100-2H3a1 1 0 000 2h1z" fillRule="evenodd" clipRule="evenodd" />
                </svg>
              </button> */}
              {/* Dark mode switcher end */}
            <button className="navbar-burger flex items-center rounded focus:outline-none" onClick={handleToggle}> 
              <svg className="text-white bg-sky-500 hover:bg-sky-600 block h-8 w-8 p-2 rounded" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" fill="currentColor">
                <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"></path>
              </svg>
            </button>
          </div>
        </div>
        <div className='navbar-menu relative z-30'>
          <div className='hidden lg:block navbar-backdrop fixed lg:hidden inset-0 bg-gray-800 opacity-10'></div>
          <nav className={`side-nav fixed top-0 left-0 bottom-0 z-20 flex flex-col pt-6 pb-0 overflow-y-auto bg-white dark:bg-gray-800 md:bg-transparent dark:md:bg-transparent ${isActive ? "" : "active"}`}>
            {/* <div className={`side-nav-overlay absolute top-0 left-0 bg-black bg-opacity-10 z-10 md:hidden`} onClick={handleToggle}></div> */}
            <div className='hidden lg:flex flex w-full items-center justify-between px-6 pb-6'>
              <Link to="/" className="text-xl text-white font-semibold" onClick={handleToggle}>
                  <div className="font-extrabold text-sky-700 dark:text-white text-3xl tracking-widest mt-0">
                    <img src="images/metaple-icon.png" className='h-16'></img>
                  </div>
              </Link>
              {/* Dark mode switcher */}
              {/* <button id="theme-toggle" type="button" className="text-sky-600 dark:text-gray-100 hover:bg-sky-200 dark:hover:bg-white dark:hover:bg-opacity-25 focus:outline-none focus:ring-0 focus:ring-gray-100 dark:focus:ring-gray-100 rounded-lg text-sm p-2 animate-bounce" onCLick={darkToggle}>
                <svg id="theme-toggle-dark-icon" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path d="M17.293 13.293A8 8 0 016.707 2.707a8.001 8.001 0 1010.586 10.586z" />
                </svg>
                <svg id="theme-toggle-light-icon" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10 2a1 1 0 011 1v1a1 1 0 11-2 0V3a1 1 0 011-1zm4 8a4 4 0 11-8 0 4 4 0 018 0zm-.464 4.95l.707.707a1 1 0 001.414-1.414l-.707-.707a1 1 0 00-1.414 1.414zm2.12-10.607a1 1 0 010 1.414l-.706.707a1 1 0 11-1.414-1.414l.707-.707a1 1 0 011.414 0zM17 11a1 1 0 100-2h-1a1 1 0 100 2h1zm-7 4a1 1 0 011 1v1a1 1 0 11-2 0v-1a1 1 0 011-1zM5.05 6.464A1 1 0 106.465 5.05l-.708-.707a1 1 0 00-1.414 1.414l.707.707zm1.414 8.486l-.707.707a1 1 0 01-1.414-1.414l.707-.707a1 1 0 011.414 1.414zM4 11a1 1 0 100-2H3a1 1 0 000 2h1z" fillRule="evenodd" clipRule="evenodd" />
                </svg>
              </button> */}
              {/* Dark mode switcher end */}
            </div>
            <div className="px-4 pb-6 relative z-20">
              <ul className="mb-2 text-sm font-medium" id="ulMenu">
                <li>
                <Link to="/" className="flex items-center pl-3 py-3 pr-4 my-0.5 text-black dark:text-gray-200 hover:bg-black hover:bg-opacity-5 dark:hover:bg-white dark:hover:bg-opacity-25 rounded transition-all" onClick={handleToggle}>
                      <span className="inline-block mr-3 text-xl">
                          <i className="las la-exchange-alt"></i>
                      </span>
                      Exchange
                  </Link>
                </li>
                {/* <li>
                    <a className="flex items-center pl-3 py-3 pr-4 my-0.5 text-black dark:text-gray-200 hover:bg-black hover:bg-opacity-5 dark:hover:bg-white dark:hover:bg-opacity-25 rounded transition-all" href="#">
                        <span className="inline-block mr-3 text-xl">
                            <i className="las la-hand-holding-usd"></i>
                        </span>
                        <span>Earn</span>
                    </a>
                </li> */}
                <li>
                  <Link to="/farming" className="flex items-center pl-3 py-3 pr-4 my-0.5 text-black dark:text-gray-200 hover:bg-black hover:bg-opacity-5 dark:hover:bg-white dark:hover:bg-opacity-25 rounded transition-all" onClick={handleToggle}>
                        <span className="inline-block mr-3 text-xl">
                            <i className="las la-tractor"></i>
                        </span>
                        Farming
                  </Link>
                </li>
                <li>
                  <Link to="/pools" className="flex items-center pl-3 py-3 pr-4 my-0.5 text-black dark:text-gray-200 hover:bg-black hover:bg-opacity-5 dark:hover:bg-white dark:hover:bg-opacity-25 rounded transition-all" onClick={handleToggle}>
                    <span className="inline-block mr-3 text-xl">
                      <i className="las la-chart-pie" />
                    </span>
                    <span>Pools</span>
                  </Link>
                </li>
                <li>
                  <Link to="/referral" className="flex items-center pl-3 py-3 pr-4 my-0.5 text-black dark:text-gray-200 hover:bg-black hover:bg-opacity-5 dark:hover:bg-white dark:hover:bg-opacity-25 rounded transition-all" onClick={handleToggle}>
                    <span className="inline-block mr-3 text-xl">
                      <i className="lab la-slideshare" />
                    </span>
                    <span>Referral</span>
                  </Link>
                </li>
                {/* <li>
                  <Link to="/crypto-loan-overview" className="flex items-center pl-3 py-3 pr-4 my-0.5 text-black dark:text-gray-200 hover:bg-black hover:bg-opacity-5 dark:hover:bg-white dark:hover:bg-opacity-25 rounded transition-all" href="referral.php">
                    <span className="inline-block mr-3 text-xl">
                      <i className="lab la-bitcoin"></i>
                    </span>
                    <span>Crypto Loans</span>
                  </Link>
                </li> */}
                {/* <li>
                  <a className="flex items-center pl-3 py-3 pr-4 my-0.5 text-black dark:text-gray-200 hover:bg-black hover:bg-opacity-5 dark:hover:bg-white dark:hover:bg-opacity-25 rounded transition-all" href="#">
                    <span className="inline-block mr-3 text-xl">
                      <i className="las la-hand-holding-usd" />
                    </span>
                    <span>DEX</span>
                  </a>
                </li> */}
                {/* <li>
                  <a className="flex items-center pl-3 py-3 pr-4 my-0.5 text-black dark:text-gray-200 hover:bg-black hover:bg-opacity-5 dark:hover:bg-white dark:hover:bg-opacity-25 rounded transition-all" href="#">
                    <span className="inline-block mr-3 text-xl">
                      <i className="las la-tools" />
                    </span>
                    <span>Tools</span>
                  </a>
                </li> */}
                {/* <li>
                  <a className="flex items-center pl-3 py-3 pr-4 my-0.5 text-black dark:text-gray-200 hover:bg-black hover:bg-opacity-5 dark:hover:bg-white dark:hover:bg-opacity-25 rounded transition-all" href="#">
                    <span className="inline-block mr-3 text-xl">
                      <i className="las la-hand-holding-usd" />
                    </span>
                    <span>Misc</span>
                  </a>
                </li> */}
              </ul>
              <div className="deals-box p-5 bg-black dark:bg-white bg-opacity-5 dark:bg-opacity-25 p-5 rounded-md">
                <h3 className="block mb-2 text-black dark:text-white text-lg font-medium">Social Links</h3>
                <ul className="deals-carousel">
                  <li>
                    <a target={`_blank`} href="https://github.com/MetapleFinance">
                      <div className="deal-box flex items-center justify-between">
                        <div className="deal-title flex items-center justify-between">
                          <div className="icon-box bg-[#171515]">
                            <i class="lab la-github"></i>
                          </div>
                          <div>
                            <div className="dl-title">Github</div>
                            <div className="dl-time">Repo Link</div>
                          </div>
                        </div>
                        <div className="deal-price">
                          <div className="icon-box bg-pink-500">
                            <i class="las la-external-link-alt"></i>
                          </div>
                        </div>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a target={`_blank`} href="https://metaplefinance.medium.com/">
                      <div className="deal-box flex items-center justify-between">
                        <div className="deal-title flex items-center justify-between">
                          <div className="icon-box bg-[#66cdaa]">
                            <i class="lab la-medium"></i>
                          </div>
                          <div>
                            <div className="dl-title">Medium</div>
                            <div className="dl-time">Recent Blogs</div>
                          </div>
                        </div>
                        <div className="deal-price">
                          <div className="icon-box bg-pink-500">
                            <i class="las la-external-link-alt"></i>
                          </div>
                        </div>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a target={`_blank`} href="https://www.youtube.com/channel/UCPwpi8vXIGDE6eZbV4gXRdQ">
                      <div className="deal-box flex items-center justify-between">
                        <div className="deal-title flex items-center justify-between">
                          <div className="icon-box bg-[#FF0000]">
                            <i className="lab la-youtube"></i>
                          </div>
                          <div>
                            <div className="dl-title">YouTube</div>
                            <div className="dl-time">Updated Videos</div>
                          </div>
                        </div>
                        <div className="deal-price">
                          <div className="icon-box bg-pink-500">
                            <i className="las la-external-link-alt"></i>
                          </div>
                        </div>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a target={`_blank`} href="https://twitter.com/MetapleFinance/">
                      <div className="deal-box flex items-center justify-between">
                        <div className="deal-title flex items-center justify-between">
                          <div className="icon-box bg-[#00acee]">
                            <i className="lab la-twitter"></i>
                          </div>
                          <div>
                            <div className="dl-title">Twitter</div>
                            <div className="dl-time">Latest Tweets</div>
                          </div>
                        </div>
                        <div className="deal-price">
                          <div className="icon-box bg-pink-500">
                            <i className="las la-external-link-alt"></i>
                          </div>
                          {/* <div className="price">$747.22</div> */}
                        </div>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a target={`_blank`} href="https://www.reddit.com/r/MetapleFinance/">
                      <div className="deal-box flex items-center justify-between">
                        <div className="deal-title flex items-center justify-between">
                          <div className="icon-box bg-[#ff4500]">
                            <i className="lab la-reddit"></i>
                          </div>
                          <div>
                            <div className="dl-title">Reddit</div>
                            <div className="dl-time">Read Posts</div>
                          </div>
                        </div>
                        <div className="deal-price">
                          <div className="icon-box bg-pink-500">
                            <i className="las la-external-link-alt"></i>
                          </div>
                        </div>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a target={`_blank`} href="https://t.me/MetapleFinance">
                      <div className="deal-box flex items-center justify-between">
                        <div className="deal-title flex items-center justify-between">
                          <div className="icon-box bg-[#00acee]">
                            <i className="lab la-telegram-plane"></i>
                          </div>
                          <div>
                            <div className="dl-title">Telegram</div>
                            <div className="dl-time">Join group</div>
                          </div>
                        </div>
                        <div className="deal-price">
                          <div className="icon-box bg-pink-500">
                            <i className="las la-external-link-alt"></i>
                          </div>
                        </div>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a target={`_blank`} href="https://www.facebook.com/TheMetapleFinance">
                      <div className="deal-box flex items-center justify-between">
                        <div className="deal-title flex items-center justify-between">
                          <div className="icon-box bg-[#4267B2]">
                            <i className="lab la-facebook-f"></i>
                          </div>
                          <div>
                            <div className="dl-title">Facebook</div>
                            <div className="dl-time">Latest Posts</div>
                          </div>
                        </div>
                        <div className="deal-price">
                          <div className="icon-box bg-pink-500">
                            <i className="las la-external-link-alt"></i>
                          </div>
                        </div>
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
              {/* <div className="deals-box p-5 bg-black dark:bg-white bg-opacity-5 dark:bg-opacity-25 p-5 rounded-md">
                <h3 className="block mb-2 text-black dark:text-white text-lg font-medium">Live Trading Deals</h3>
                <ul className="deals-carousel">
                  <li>
                    <div className="deal-box flex items-center justify-between">
                      <div className="deal-title flex items-center justify-between">
                        <div className="icon-box bg-pink-500"><i className="las la-exchange-alt" /></div>
                        <div>
                          <div className="dl-title">Swap</div>
                          <div className="dl-time">10:51:PM</div>
                        </div>
                      </div>
                      <div className="deal-price">
                        <div><span className="text-black dark:text-white">MTPL/</span><span className="text-black dark:text-white opacity-40">WBNB</span></div>
                        <div className="price">$747.22</div>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="deal-box flex items-center justify-between">
                      <div className="deal-title flex items-center justify-between">
                        <div className="icon-box bg-pink-500"><i className="las la-exchange-alt" /></div>
                        <div>
                          <div className="dl-title">Swap</div>
                          <div className="dl-time">10:51:PM</div>
                        </div>
                      </div>
                      <div className="deal-price">
                        <div><span className="text-black dark:text-white">MTPL/</span><span className="text-black dark:text-white opacity-40">WBNB</span></div>
                        <div className="price">$747.22</div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div> */}
            </div>
          </nav>
        </div>
      </nav>
      <Outlet />
    </>
  );
}


export default Sidebar;
