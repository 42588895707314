import React from "react";
import { Suspense, lazy } from 'react';
import {Navigate, useRoutes, useLocation} from 'react-router-dom';
// layouts
import {BITCOIN, FACEBOOK, GITHUB, MEDIUM, REDDIT, TELEGRAM, TWITTER, WHITEPAPER, YOUTUBE} from "../contexts/Constants";
import Redirect from "../components/Redirect";
// import Sidebar from "../Sidebar";
import Sidebar from "../Sidebar";

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = pathname.includes('/dashboard');

  return (
    <Suspense
      fallback={
        <div>
          Loading...
        </div>
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    // Dashboard Routes
    {
      path: '/',
      element: (
        <Sidebar />
      ),
      children: [
        { path: '/', element: <Navigate to="/home" replace /> },
        { path: 'home', element: <MTPLHome /> },
        { path: 'farming', element: <MTPLFarming /> },
        { path: 'pools', element: <MTPLPools /> },
        { path: 'referral', element: <MTPLReferral /> },
        { path: 'whitepaper', element: <Redirect link={WHITEPAPER} target="_blank" /> },
        { path: 'github', element: <Redirect link={GITHUB} target="_blank" /> },
        { path: 'youtube', element: <Redirect link={YOUTUBE} target="_blank" /> },
        { path: 'bitcointalk', element: <Redirect link={BITCOIN} target="_blank" /> },
        { path: 'medium', element: <Redirect link={MEDIUM} target="_blank" /> },
        { path: 'facebook', element: <Redirect link={FACEBOOK} target="_blank" /> },
        { path: 'twitter', element: <Redirect link={TWITTER} target="_blank" /> },
        { path: 'reddit', element: <Redirect link={REDDIT} target="_blank" /> },
        { path: 'telegram', element: <Redirect link={TELEGRAM} target="_blank" /> }
      ]
    },

  ]);
}

const MTPLHome = Loadable(lazy(() => import('../pages/MTPLHome')));
const MTPLFarming = Loadable(lazy(() => import('../pages/MTPLFarming')))
const MTPLPools = Loadable(lazy(() => import('../pages/MTPLPools')))
const MTPLReferral = Loadable(lazy(() => import('../pages/MTPLReferral')))
