import { createSlice } from '@reduxjs/toolkit';
import BigNumber from 'bignumber.js';
import poolsConfig from '../../config/constants/pools';
import { getPoolApr } from '../../utils/apr';
import { getBalanceNumber } from '../../utils/formatBalance';
import {fetchPoolsBlockLimits, fetchPoolsTotalStaking, fetchPoolWeights, fetchTotalAllocPoint} from './fetchPools';
import {
    fetchPoolsAllowance,
    fetchUserBalances,
    fetchUserStakeBalances,
    fetchUserPendingRewards,
    fetchUserLastInvested,
} from './fetchPoolsUser';
import { getTokenPricesFromFarm } from './helpers';
import {BIG_ZERO} from "../../utils/bigNumber";

const initialState = {
    data: [...poolsConfig],
    userDataLoaded: false,
    udeVault: {
        totalShares: null,
        pricePerFullShare: null,
        totalUdeInVault: null,
        estimatedUdeBountyReward: null,
        totalPendingUdeHarvest: null,
        fees: {
            performanceFee: null,
            callFee: null,
            withdrawalFee: null,
            withdrawalFeePeriod: null,
        },
        userData: {
            isLoading: true,
            userShares: null,
            udeAtLastUserAction: null,
            lastDepositedTime: null,
            lastUserActionTime: null,
        },
    },
};
// Thunks
export const fetchPoolsPublicDataAsync = (currentBlock) => async (dispatch, getState) => {
    const blockLimits = await fetchPoolsBlockLimits();
    const totalStakings = await fetchPoolsTotalStaking();
    // console.log(totalStakings)
    const poolWeights = await fetchPoolWeights()
    const prices = await getTokenPricesFromFarm(getState().farms.data);
    const totalAlloc = await fetchTotalAllocPoint()
    // let totalAlloc = 0
    
    // poolWeights.forEach((p) => {
    //     totalAlloc = totalAlloc + parseFloat(p.poolWeight)
    // })

    console.log(totalAlloc)

    const liveData = poolsConfig.map((pool) => {
        const blockLimit = blockLimits.find((entry) => entry.sousId === pool.sousId);
        const totalStaking = totalStakings.find((entry) => entry.sousId === pool.sousId);
        const poolWeight = poolWeights.find((entry) => entry.sousId === pool.sousId);
        const totalAllocToPool = totalAlloc.find((p) => p.sousId == pool.sousId)
        const poolW = parseFloat(poolWeight.poolWeight) / parseFloat(totalAllocToPool.poolWeight)
        const isPoolEndBlockExceeded = currentBlock > 0 && blockLimit ? currentBlock > Number(blockLimit.endBlock) : false;
        const isPoolFinished = pool.isFinished || isPoolEndBlockExceeded;
        const stakingTokenAddress = pool.stakingToken.address ? pool.stakingToken.address.toLowerCase() : null;
        const stakingTokenPrice = stakingTokenAddress ? prices[stakingTokenAddress] : 0;
        const earningTokenAddress = pool.earningToken.address ? pool.earningToken.address.toLowerCase() : null;
        const earningTokenPrice = earningTokenAddress ? prices[earningTokenAddress] : 0;

        console.log(poolW)

        const apr = !isPoolFinished
            ? getPoolApr(stakingTokenPrice, earningTokenPrice, getBalanceNumber(new BigNumber(totalStaking.totalStaked), pool.stakingToken.decimals), parseFloat(pool.tokenPerBlock), poolW)
            : 0;
        return {...blockLimit, ...totalStaking, stakingTokenPrice,
            earningTokenPrice,
            apr, isFinished: isPoolFinished, poolWeight: parseFloat(poolWeight.poolWeight)};
    });
    dispatch(setPoolsPublicData(liveData));
};
export const fetchPoolsStakingLimitsAsync = () => async (dispatch, getState) => {
    const stakingLimitData = poolsConfig.map((pool) => {
        const stakingLimit = BIG_ZERO;
        return {
            sousId: pool.sousId,
            stakingLimit: stakingLimit.toJSON(),
        };
    });
    dispatch(setPoolsPublicData(stakingLimitData));
};
export const fetchPoolsUserDataAsync = (account) => async (dispatch) => {
    const allowances = await fetchPoolsAllowance(account);
    const stakingTokenBalances = await fetchUserBalances(account);
    const stakedBalances = await fetchUserStakeBalances(account);
    const pendingRewards = await fetchUserPendingRewards(account);
    const userInvested = await fetchUserLastInvested(account);
    // // console.log(userInvested[0])
    const userData = poolsConfig.map((pool) => ({
        sousId: pool.sousId,
        allowance: allowances[pool.sousId],
        stakingTokenBalance: stakingTokenBalances[pool.sousId],
        stakedBalance: stakedBalances[pool.sousId],
        pendingReward: pendingRewards[pool.sousId],
        userLast: parseFloat(userInvested[pool.sousId])
    }));
    dispatch(setPoolsUserData(userData));
};
export const updateUserAllowance = (sousId, account) => async (dispatch) => {
    const allowances = await fetchPoolsAllowance(account);
    dispatch(updatePoolsUserData({ sousId, field: 'allowance', value: allowances[sousId] }));
};
export const updateUserBalance = (sousId, account) => async (dispatch) => {
    const tokenBalances = await fetchUserBalances(account);
    dispatch(updatePoolsUserData({ sousId, field: 'stakingTokenBalance', value: tokenBalances[sousId] }));
};
export const updateUserStakedBalance = (sousId, account) => async (dispatch) => {
    const stakedBalances = await fetchUserStakeBalances(account);
    dispatch(updatePoolsUserData({ sousId, field: 'stakedBalance', value: stakedBalances[sousId] }));
};
export const updateUserPendingReward = (sousId, account) => async (dispatch) => {
    const pendingRewards = await fetchUserPendingRewards(account);
    dispatch(updatePoolsUserData({ sousId, field: 'pendingReward', value: pendingRewards[sousId] }));
};

export const PoolsSlice = createSlice({
    name: 'Pools',
    initialState,
    reducers: {
        setPoolsPublicData: (state, action) => {
            const livePoolsData = action.payload;
            state.data = state.data.map((pool) => {
                const livePoolData = livePoolsData.find((entry) => entry.sousId === pool.sousId);
                return {...pool, ...livePoolData};
            });
        },
        setPoolsUserData: (state, action) => {
            const userData = action.payload;
            state.data = state.data.map((pool) => {
                const userPoolData = userData.find((entry) => entry.sousId === pool.sousId);
                return {...pool, userData: userPoolData};
            });
            state.userDataLoaded = true;
        },
        updatePoolsUserData: (state, action) => {
            const { field, value, sousId } = action.payload;
            const index = state.data.findIndex((p) => p.sousId === sousId);
            if (index >= 0) {
                state.data[index] = {...state.data[index], userData: {...state.data[index].userData, [field]: value}};
            }
        },
    }
});
// Actions
export const { setPoolsPublicData, setPoolsUserData, updatePoolsUserData } = PoolsSlice.actions;
export default PoolsSlice.reducer;
