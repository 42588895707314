export const ALL_TOKENS = {
    "bnb" : "bnb",
    "mlx": "mlx",
    "busd": "0xe9e7cea3dedca5984780bafc599bd69add087d56",
    "wbnb": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
    "cake" : "0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82",
    "btcb": "0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c",
    "bunny": "0xc9849e6fdb743d08faee3e34dd2d1bc69ea11a51",
    "weth": "0x2170ed0880ac9a755fd29b2688956bd959f933f8",
    "usdt": "0x55d398326f99059ff775485246999027b3197955",
    "venus": "0xcf6bb5389c92bdda8a3747ddb454cb7a64626c63",
    "auto": "0xa184088a740c695E156F91f5cC086a06bb78b827"
}
export const BNB_ADDR = '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c'