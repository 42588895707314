import { InjectedConnector } from '@web3-react/injected-connector'
import { WalletConnectConnector } from '@web3-react/walletconnect-connector'
import { BscConnector } from '@binance-chain/bsc-connector'

import { ethers } from 'ethers'
import getNodeUrl from './getRpcUrl'

export const ConnectorNames = {
    Injected: "injected",
    WalletConnect: "walletconnect",
    BSC: "bsc"
}

const POLLING_INTERVAL = 12000
const rpcUrl = getNodeUrl()
const chainId = parseInt("56", 10)
const chainId2 = parseInt("97", 10)

const injected = new InjectedConnector({ supportedChainIds: [chainId, chainId2] })

const walletconnect = new WalletConnectConnector({
    rpc: { [chainId]: "https://bsc-dataseed1.ninicoin.io/", [chainId2]: "https://data-seed-prebsc-2-s2.binance.org:8545/" },
    qrcode: true,
    pollingInterval: POLLING_INTERVAL,
})

const bscConnector = new BscConnector({ supportedChainIds: [chainId, chainId2] })
const a = [];
a[ConnectorNames.Injected]= injected
a[ConnectorNames.WalletConnect]= walletconnect
a[ConnectorNames.BSC]= bscConnector

export const connectorsByName = (connectID) => a[connectID]

export const getLibrary = (provider) => {
    const library = new ethers.providers.Web3Provider(provider)
    library.pollingInterval = POLLING_INTERVAL
    return library
}

/**
 * BSC Wallet requires a different sign method
 * @see https://docs.binance.org/smart-chain/wallet/wallet_api.html#binancechainbnbsignaddress-string-message-string-promisepublickey-string-signature-string
 */
export const signMessage = async (provider, account, message) => {
    if (window.BinanceChain) {
        const { signature } = await window.BinanceChain.bnbSign(account, message)
        return signature
    }

    /**
     * Wallet Connect does not sign the message correctly unless you use their method
     * @see https://github.com/WalletConnect/walletconnect-monorepo/issues/462
     */
    if (provider.provider?.wc) {
        const wcMessage = ethers.utils.hexlify(ethers.utils.toUtf8Bytes(message))
        const signature = await provider.provider?.wc.signPersonalMessage([wcMessage, account])
        return signature
    }

    return provider.getSigner(account).signMessage(message)
}