import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import farmsConfig from '../../config/constants/farms';
import isArchivedPid from '../../utils/farmHelpers';
import priceHelperLpsConfig from '../../config/constants/priceHelpersLps';
import fetchFarms from './fetchFarms';
import fetchFarmsPrices from './fetchFarmsPrices';
import {
    fetchFarmUserEarnings,
    fetchFarmUserAllowances,
    fetchFarmUserTokenBalances,
    fetchFarmUserStakedBalances,
    fetchFarmUserInvested,
} from './fetchFarmUser';

const noAccountFarmConfig = farmsConfig.map((farm) => ({...farm, userData: {
        allowance: '0',
        tokenBalance: '0',
        stakedBalance: '0',
        earnings: '0',
        userInvested: '0'
    }}));
const initialState = {
    data: noAccountFarmConfig,
    loadArchivedFarmsData: false,
    userDataLoaded: false,
};
export const nonArchivedFarms = farmsConfig.filter(({ pid }) => !isArchivedPid(pid));
// Async thunks
export const fetchFarmsPublicDataAsync = createAsyncThunk('farms/fetchFarmsPublicDataAsync', async (pids) => {
    const farmsToFetch = farmsConfig.filter((farmConfig) => pids.includes(farmConfig.pid));
    // Add price helper farms
    const farmsWithPriceHelpers = farmsToFetch.concat(priceHelperLpsConfig);
    const farms = await fetchFarms(farmsWithPriceHelpers);
    // // console.log("FETCHING PUBLIC DATA PRICES", farms)
    const farmsWithPrices = await fetchFarmsPrices(farms);
    // Filter out price helper LP config farms
    const farmsWithoutHelperLps = farmsWithPrices.filter((farm) => farm.pid || farm.pid === 0);
    return farmsWithoutHelperLps;
});
export const fetchFarmUserDataAsync = createAsyncThunk('farms/fetchFarmUserDataAsync', async ({ account, pids }) => {
    const farmsToFetch = farmsConfig.filter((farmConfig) => pids.includes(farmConfig.pid));
    const userFarmAllowances = await fetchFarmUserAllowances(account, farmsToFetch);
    const userFarmTokenBalances = await fetchFarmUserTokenBalances(account, farmsToFetch);
    const userStakedBalances = await fetchFarmUserStakedBalances(account, farmsToFetch);
    const userInvested = await fetchFarmUserInvested(account, farmsToFetch);
    const userFarmEarnings = await fetchFarmUserEarnings(account, farmsToFetch);
    // // console.log(userInvested[0])
    return userFarmAllowances.map((farmAllowance, index) => ({
            pid: farmsToFetch[index].pid,
            allowance: userFarmAllowances[index],
            tokenBalance: userFarmTokenBalances[index],
            stakedBalance: userStakedBalances[index],
            earnings: userFarmEarnings[index],
            userLast: userInvested[index]
        }));
});
export const farmsSlice = createSlice({
    name: 'Farms',
    initialState,
    reducers: {
        setLoadArchivedFarmsData: (state, action) => {
            const loadArchivedFarmsData = action.payload;
            state.loadArchivedFarmsData = loadArchivedFarmsData;
        },
    },
    extraReducers: (builder) => {
        // Update farms with live data
        builder.addCase(fetchFarmsPublicDataAsync.fulfilled, (state, action) => {
            state.data = state.data.map((farm) => {
                const liveFarmData = action.payload.find((farmData) => farmData.pid === farm.pid);
                return {...farm, ...liveFarmData};
            });
        });
        // Update farms with user data
        builder.addCase(fetchFarmUserDataAsync.fulfilled, (state, action) => {
            action.payload.forEach((userDataEl) => {
                const { pid } = userDataEl;
                const index = state.data.findIndex((farm) => farm.pid === pid);
                state.data[index] = {...state.data[index], userData: userDataEl};
            });
            state.userDataLoaded = true;
        });
    },
});
// Actions
export const { setLoadArchivedFarmsData } = farmsSlice.actions;
export default farmsSlice.reducer;
